import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const ActivityDisplayer = ({ question, disabled, onInteraction, isActive, profile }) => (
    <button
        className={classnames(
            'activity-card__container',
            {
                'activity-card--primary': true === isActive,
            },
        )}
        onClick={() => { if (!disabled) { onInteraction(profile); } }}
        type="button"
    >
        <img alt="checked" src={`/img/icons/${isActive ? 'check' : 'checkbox'}.svg`} />
        <span>
            {question}
        </span>
    </button>
);

ActivityDisplayer.propTypes = {
    disabled: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    onInteraction: PropTypes.func,
    profile: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
};

export default ActivityDisplayer;
