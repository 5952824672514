import { createSlice } from "@reduxjs/toolkit";
import FormState from "../../../constants/form-state.types";
import Phase from "../../../constants/phase.types";

const DEFAULT_PHASE = Phase.MINI_MARCO;

/**
 * Store slice to store the phase of the current form (between Mini Marco and Full Marco)
 */
export const exerciseDataSlice = createSlice({
    name: "exerciseData",
    initialState: { phase: DEFAULT_PHASE },
    reducers: {
        goToPhase: (state, action) => ({
            phase: action.payload,
        }),
    },
});

export const phaseSelector = state => state.exerciseData.phase;

export const { goToPhase } = exerciseDataSlice.actions;

export default exerciseDataSlice.reducer;

const hasMaxiStarted = (form)=>  form.responses.step1 && (form.responses.step1[31] !== undefined)

const detectState = (form) => {
        if (form.completedAt !== null)
            return FormState.MAXI_ENDED;

        if (form.completedAt === null && hasMaxiStarted(form)) {
            return FormState.MAXI_STARTED;
        }

        if (form.completedAt === null && !hasMaxiStarted(form) && form.miniCompletedAt !== null) {
            return FormState.MINI_ENDED;
        }

        return FormState.MINI_STARTED;
}

export const detectPhase = (form) => {
    const state = detectState(form);
    if (state === FormState.MINI_STARTED || state === FormState.MINI_ENDED) {
        return Phase.MINI_MARCO;
    }

    return Phase.MAXI_MARCO;
}
