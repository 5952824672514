import React from "react";
import { useDispatch } from "react-redux";
import { setMenuOpen } from "../../../store/slices/menu";

const CloseButton = () => {
    const dispatch = useDispatch();

    return (
        <button onClick={() => dispatch(setMenuOpen(false))} type="button">
            <img alt="close" src="/img/icons/white-cross.svg" />
        </button>
    );
};

export default CloseButton;
