import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import requireAuth from "../hoc/requireAuth";
import requireUser from "../hoc/requireUser";
import { useAppData } from "../hooks/useAppData";
import Account from "../routes/Account";
import Admin from "../routes/Admin";
import CheckLogin from "../routes/CheckLogin";
import Confidentiality from "../routes/Legal/Confidentiality";
import LegalNotices from "../routes/Legal/LegalNotices";
import TermOfServices from "../routes/Legal/TermOfServices";
import NotFound from "../routes/NotFound";
import PasswordReset from "../routes/PasswordReset";
import Exercise from "../routes/personality-form/Exercise";
import ExerciseRedirector from "../routes/personality-form/ExerciseRedirector";
import Step7 from "../routes/personality-form/Form";
import LastResults from "../routes/personality-form/LastResults";
import RestitutionWrapper from "../routes/personality-form/RestitutionWrapper";
import Results from "../routes/personality-form/Results";
import Step6Rank from "../routes/personality-form/Step6-rank";
import StepTransition from "../routes/personality-form/StepTransition";
import StepTransitionGif from "../routes/personality-form/StepTransitionGif";
import { languageSelector } from "../store/slices/language";
import { getAllLangs } from "../utils/lang";

const LocalizedRoutes = () => {
    const { language } = useSelector(languageSelector);
    const { pathname } = useLocation();
    const { MARCO_V3_URL, isWebview } = useAppData();

    const redirectToMarcoV3 = (path = "") => {
        // Redirect to last results, or logout page, or home page, otherwise, redirect to account
        if (path.includes("last-results")) {
            window.location.href = `${MARCO_V3_URL}/${language}/last-results${isWebview ? "?webview=true" : ""}`;
        } else if (path.includes("logout")) {
            window.location.href = `${MARCO_V3_URL}/${language}/logout${isWebview ? "?webview=true" : ""}`;
        } else if (!path) {
            window.location.href = `${MARCO_V3_URL}/${language}${isWebview ? "?webview=true" : ""}`;
        } else {
            window.location.href = `${MARCO_V3_URL}/${language}/account${isWebview ? "?webview=true" : ""}`;
        }

        return null;
    };

    const Routes = useMemo(
        () => (
            <Switch>
                <Route component={() => redirectToMarcoV3()} path={`/${language}`} exact />
                <Route component={() => redirectToMarcoV3("/logout")} path={`/${language}/logout`} exact />
                <Route component={requireAuth(CheckLogin)} path={`/${language}/check-login`} exact />
                <Route component={RestitutionWrapper} path={`/${language}/pdf/restitution/:formId/:phase`} exact />
                <Route component={requireAuth(Admin)} path="/fr/admin" exact />
                <Route component={() => <Redirect to="/fr/admin" />} path={`/${language}/admin`} exact />
                <Route
                    component={() => redirectToMarcoV3(pathname.slice(3, pathname.length))}
                    path={`/:lang(${getAllLangs()
                        .filter(({ isAvailableV3 }) => isAvailableV3)
                        .map(({ keyLang }) => keyLang)
                        .join("|")})/*`}
                />
                <Route component={Confidentiality} path={`/${language}/confidentiality`} exact />
                <Route component={LegalNotices} path={`/${language}/legalnotices`} exact />
                <Route component={PasswordReset} path={`/${language}/password-reset/:token`} exact />
                <Route component={TermOfServices} path={`/${language}/tos`} exact />
                <Route component={Results} path={`/${language}/results/:formId`} exact />
                <Route component={requireAuth(requireUser(LastResults))} path={`/${language}/last-results`} exact />
                <Route component={requireAuth(requireUser(Account))} path={`/${language}/account`} exact />
                <Route
                    component={requireAuth(requireUser(ExerciseRedirector))}
                    path={`/${language}/step/:step([1-6]{1})`}
                    exact
                />
                <Route
                    component={requireAuth(requireUser(StepTransition))}
                    path={`/${language}/step/:step([0-6]{1})/transition`}
                    exact
                />
                <Route
                    component={requireAuth(requireUser(Exercise))}
                    path={`/${language}/step/:step([1-6]{1})/:substep(1?[0-9]{1,2}|2[0-6][0-9]|27[0-6])`}
                    exact
                />
                <Route component={requireAuth(requireUser(Step6Rank))} path={`/${language}/step/6/rank`} exact />
                <Route component={requireAuth(requireUser(Step7))} path={`/${language}/step/7`} exact />
                <Route
                    component={requireAuth(requireUser(StepTransitionGif))}
                    path={`/${language}/processing/:formId`}
                    exact
                />
                <Route component={NotFound} path={`/${language}/*`} status={404} exact />
            </Switch>
        ),
        [language]
    );

    return Routes;
};

export default LocalizedRoutes;
