import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { STATUS_ADMIN } from "../../../../constants";
import useLogout from "../../../hooks/useLogout";
import { languageSelector } from "../../../store/slices/language";
import { isMenuOpenSelector, setMenuOpen } from "../../../store/slices/menu";
import { deleteAccount as deleteAccountAction } from "../../../store/slices/user";
import DeleteAccountModal from "../../DeleteAccountModal";

import "./style.scss";

const SecondaryMenu = () => {
    const { t } = useTranslation();
    const menuIsOpen = useSelector(isMenuOpenSelector);
    const logout = useLogout();
    const dispatch = useDispatch();
    const {
        auth: { status },
        user,
    } = useSelector(state => state);
    const { language } = useSelector(languageSelector);

    const showError = () => {
        // eslint-disable-next-line no-alert
        window.alert(t("error.cannot_delete_account"));
    };

    const deleteAccount = async () => {
        const deletionIsSuccessful = await dispatch(deleteAccountAction());
        if (deletionIsSuccessful) {
            logout();
        } else {
            showError();
        }
    };

    const onDeleteAccount = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => <DeleteAccountModal onClose={onClose} onConfirm={deleteAccount} />,
        });
    };

    return (
        <ul className="secondary-menu">
            {menuIsOpen && user ? (
                <>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => {
                                dispatch(setMenuOpen(false));
                                logout();
                            }}
                            type="button"
                        >
                            {t("account.card.logout")}
                        </button>
                    </li>
                    {status !== STATUS_ADMIN && (
                        <li className="secondary-menu__link">
                            <button
                                onClick={() => {
                                    dispatch(setMenuOpen(false));
                                    onDeleteAccount();
                                }}
                                type="button"
                            >
                                {t("account.card.delete_account")}
                            </button>
                        </li>
                    )}
                </>
            ) : (
                status !== STATUS_ADMIN && (
                    <>
                        <li className="secondary-menu__link">
                            <Link
                                onClick={() => {
                                    dispatch(setMenuOpen(false));
                                }}
                                title={t("common.footer.legal_mentions")}
                                to={`/${language}/legalnotices`}
                            >
                                {t("common.footer.legal_mentions")}
                            </Link>
                        </li>
                        <li className="secondary-menu__link">
                            <Link
                                onClick={() => {
                                    dispatch(setMenuOpen(false));
                                }}
                                title={t("common.terms_of_service")}
                                to={`/${language}/tos`}
                            >
                                {t("common.terms_of_service")}
                            </Link>
                        </li>
                        <li className="secondary-menu__link">
                            <Link
                                onClick={() => {
                                    dispatch(setMenuOpen(false));
                                }}
                                title={t("common.footer.privacy_policy")}
                                to={`/${language}/confidentiality`}
                            >
                                {t("common.footer.privacy_policy")}
                            </Link>
                        </li>
                    </>
                )
            )}
        </ul>
    );
};

export default SecondaryMenu;
