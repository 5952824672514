
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import getPdfName from "../../../../api/utils/getPdfName";
import Phase from "../../../../constants/phase.types";
import psicoDescriptions from "../../../../constants/psico_profile_descriptions";
import { C, I, O, P, S } from "../../../../constants/psico_profiles";
import ArchetypeBlock from "../../../components/ArchetypeBlock";
import Button from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";
import Container from "../../../components/layout/Container";
import Panel from "../../../components/layout/Panel";
import MatchingBranchesRanking from "../../../components/MatchingBranchesRanking";
import ResultsDescription from "../../../components/PanelDescription/ResultsDescription";
import PdfModal from "../../../components/PdfModal";
import RadarBlock from "../../../components/RadarBlock";
import ProfileCard from "../../../components/ResultProfileCard";
import ShareButton from "../../../components/ShareButton";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import { getLastForms, restartFromMiniMarco } from "../../../store/actions";
import { goToPhase } from "../../../store/slices/exerciseData";
import { languageSelector } from "../../../store/slices/language";
import { GET } from "../../../utils/http";
import { goToLastEmptyQuestion } from "../../../utils/menu";
import { ROUTES } from "../../../utils/routes";
import { FACEBOOK, LINKEDIN } from "../../../utils/shareOnSocialNetwork";
import "./style.scss";

const getGender = originalGender => ("male" === originalGender ? originalGender : "female");

const DOWNLOAD_PDF_ELEMENT_ID = "dlFile";

const renderModal = (onClose, t) => (
    <Modal closeModal={onClose} title={t("common.share_your_results")} fullscreen>
        <div className="share-modal__buttons-container">
            <ShareButton
                afterOnClick={onClose}
                buttonContent="social_network.share.linkedin"
                shareUrl={window.location.href}
                socialNetwork={LINKEDIN}
            />
            <ShareButton
                afterOnClick={onClose}
                buttonContent="social_network.share.facebook"
                shareUrl={window.location.href}
                socialNetwork={FACEBOOK}
            />
        </div>
    </Modal>
);

const Results = ({
    match: {
        params: { formId },
    },
    userName,
}) => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const lastCompletedForm = useSelector(state => state.reconnect?.lastCompletedForm)
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [state, setState] = useState({
        createdAt: null,
        downloading: false,
        sendingByEmail: false,
        pdfUrl: null,
        user: null,
        stats: null,
    });
    const pdfName = useMemo(() => getPdfName(t("results.pdf.prefix", { lng: language }), state.createdAt, userName), [
        state,
        userName,
        language,
    ]);
    const push = useLocalizedHistoryPush();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectHome = urlParams.get("redirectHome");
        if (redirectHome) {
            push(ROUTES.Homepage);
        }
        dispatch(getLastForms());
    }, []);

    useEffect(() => {
        const getRestitutionData = async () => {
            if (formId) {
                const { user, createdAt, stats } = await GET(`/api/personality-form/restitution-data/${formId}`);
                setState({ ...state, createdAt, user, stats });
            }
        };

        getRestitutionData();
    }, [formId]);

    const onDownloadPdfClick = () => {
        setOpenPdfModal(true);
    };

    const onReportShareButtonClick = () => {
        confirmAlert({
            // eslint-disable-next-line react/prop-types
            customUI: ({ onClose }) => renderModal(onClose, t),
        });
    };

    const { user, stats } = state;
    if (!stats) {
        return null;
    }
    const { major, minor, branches, marcoIsFull } = stats;
    const psicoProfile = psicoDescriptions[`${major}${minor}`];

    const data = [
        { label: t("common.profile.P"), value: stats.profiles[P] },
        { label: t("common.profile.S"), value: stats.profiles[S] },
        { label: t("common.profile.I"), value: stats.profiles[I] },
        { label: t("common.profile.C"), value: stats.profiles[C] },
        { label: t("common.profile.O"), value: stats.profiles[O] },
    ];

    const gender = getGender(user.gender);

    const goToPhase2 = () => {
        dispatch(goToPhase(Phase.MAXI_MARCO));
        // set form data with last completed form values
        dispatch(restartFromMiniMarco());
        // push to the correct route
        push(goToLastEmptyQuestion(lastCompletedForm, Phase.MAXI_MARCO));
    };

    return (
        <Container>
            <Panel>
                <ResultsDescription
                    onReportDownloadButtonClick={onDownloadPdfClick}
                    onReportShareButtonClick={onReportShareButtonClick}
                />
            </Panel>
            {openPdfModal && <PdfModal formId={formId} language={language} onClose={() => setOpenPdfModal(false)} />}
            {/* eslint-disable jsx-a11y/anchor-has-content */}
            <a download={pdfName} href={state.pdfUrl} id={DOWNLOAD_PDF_ELEMENT_ID} style={{ display: "none" }} aria-hidden />
            <div className="results__wrapper">
                <img alt="" className="results__top" src="/img/results/top.svg" />
                <div className="results__container">
                    <ProfileCard psicoProfile={psicoProfile} />
                    <div>
                        {!marcoIsFull && (
                            <div className="results__continue">
                                <div>
                                    <h2>{t("results.continueToMarcoFull.title")}</h2>
                                    <span>{t("results.continueToMarcoFull.description")}</span>
                                </div>
                                <Button
                                    className="button--black"
                                    onClick={goToPhase2}
                                    text={t("results.continueToMarcoFull.button")}
                                />
                            </div>
                        )}
                    </div>
                    <RadarBlock data={data} gender={gender} language={language} psicoProfile={psicoProfile} />
                    <ArchetypeBlock psicoProfile={psicoProfile} userGender={gender} />
                    {marcoIsFull && <MatchingBranchesRanking branches={branches} nbBranches={10} withTitle />}
                    <div className="results__share">
                        {t("results.share.download_button_description")}
                        <Button
                            className="button--black results__share__button"
                            img="/img/icons/download-black.svg"
                            onClick={onDownloadPdfClick}
                            text={t("common.download_report")}
                        />
                        {t("results.share.share_button_description")}
                        <Button
                            className="button--black results__share__button"
                            img="/img/icons/share.svg"
                            onClick={onReportShareButtonClick}
                            text={t("common.share_your_results")}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

Results.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string.isRequired,
        }),
    }),
    userName: PropTypes.string,
};

const mapStateToProps = ({ auth: { id, name } }) => ({
    loggedUserId: id,
    userName: name,
});

export default connect(mapStateToProps)(Results);
