/**
 * Form State represents the advancement of the form
 * Each state covers all state before itself
 * For a complete description of each state, check on domain/PersonalityForm
 */
var FormState;
(function (FormState) {
    FormState[FormState["MINI_STARTED"] = 1] = "MINI_STARTED";
    FormState[FormState["MINI_ENDED"] = 2] = "MINI_ENDED";
    FormState[FormState["MAXI_STARTED"] = 3] = "MAXI_STARTED";
    FormState[FormState["MAXI_ENDED"] = 4] = "MAXI_ENDED";
})(FormState || (FormState = {}));
export default FormState;
