import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_ADMIN } from "../../../../constants";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import useWindowSize from "../../../hooks/useWindowSize";
import { checkToken } from "../../../jt-client/actions";
import { isMenuOpenSelector, setMenuOpen } from "../../../store/slices/menu";
import { getUser } from "../../../store/slices/user";
import { ROUTES } from "../../../utils/routes";
import Backdrop from "../../common/Backdrop";
import CloseButton from "../CloseButton";
import Menu from "../Menu";
import MenuButton from "../MenuButton";
import SecondaryMenu from "../SecondaryMenu";

import "./style.scss";

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const renderBackdopComponent = (child) => {
    const dispatch = useDispatch();

    return (
        <Backdrop
            className="panel__backdrop"
            onClick={(event) => {
                if (event.target === event.currentTarget) {
                    // only dispatch if the backdrop is clicked
                    // prevent dispatch from being emit due to event bubbling eg. on language switcher click
                    dispatch(setMenuOpen(false));
                }
            }}
            zIndex={499}
        >
            {child}
        </Backdrop>
    )
}

const Panel = ({ children }) => {
    const menuIsOpen = useSelector(isMenuOpenSelector);
    const dispatch = useDispatch();
    const isMobile = "xs" === useWindowSize().size;
    const {
        auth: { status },
        user,
    } = useSelector(state => state);
    const push = useLocalizedHistoryPush();

    useEffect(() => {
        async function fetchToken() {
            const result = await dispatch(checkToken());
            if (result && result.success) {
                await dispatch(getUser());
            }
        }
        if (!user) {
            fetchToken();
        }
    }, []);

    return (
        <div className="container__panel">
            <ConditionalWrapper
                condition={menuIsOpen}
                wrapper={child => renderBackdopComponent(child)}
            >
                <div className={`panel ${menuIsOpen ? "panel--open" : ""}`}>
                    <header className="panel__header">
                        <button
                            className="marco-btn"
                            disabled={status === STATUS_ADMIN}
                            onClick={() => push(ROUTES.Account)}
                            type="button"
                        >
                            <img alt="Menu" src="/img/logos/marco.svg" width="107" />
                        </button>
                        {menuIsOpen ? <CloseButton /> : <MenuButton />}
                    </header>
                    {!isMobile && !menuIsOpen && (
                        <div className={classnames("panel__inner", { "without-border": status === STATUS_ADMIN })}>
                            {children}
                        </div>
                    )}
                    {menuIsOpen && <Menu />}
                    {(!isMobile || menuIsOpen) && <SecondaryMenu />}
                </div>
            </ConditionalWrapper>
        </div>
    );
};
Panel.propTypes = {
    children: PropTypes.node,
};
export default Panel;
