import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import getTexts from "../../../constants/texts";
import { phaseSelector } from "../../store/slices/exerciseData";
import Step from "./Step";

const StepList = ({ currentStep }) => {
    const phase = useSelector(phaseSelector);

    const getStateClass = step => {
        if (currentStep + 1 === step) {
            return "current";
        }

        return currentStep + 1 > step ? "done" : "waiting";
    };

    return (
        <ul>
            {Object.keys(getTexts(phase)).map((exerciseKey, index) => {
                const { stepName } = getTexts(phase)[exerciseKey];
                const exerciseNumber = index + 1;

                return (
                    <Step
                        key={exerciseNumber}
                        description={stepName}
                        number={exerciseNumber}
                        state={getStateClass(exerciseNumber)}
                    />
                );
            })}
        </ul>
    );
};

StepList.propTypes = {
    currentStep: PropTypes.number.isRequired,
};

export default StepList;
