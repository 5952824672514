import { configureStore } from "@reduxjs/toolkit";
import { mergeRight } from "ramda";
import { reducer as reduxFormReducer } from "redux-form";
import auth from "../../../auth-client/reducers/auth";
import exerciseData from "./slices/exerciseData";
import language from "./slices/language";
import menu from "./slices/menu";
import reconnect from "./slices/reconnect";
import user from "./slices/user";

export default configureStore({
    reducer: {
        auth,
        reconnect,
        menu,
        language,
        exerciseData,
        form: reduxFormReducer.plugin({
            personalityForm: (state, { type, ...payload }) => {
                switch (type) {
                    case "SET_VALUES":
                        return { ...state, ...mergeRight(state, payload) };
                    default:
                        return state;
                }
            },
        }),
        user,
    },
});
