import downloadjs from "downloadjs";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Errors from "../../../constants/errors.json";
import { POST } from "../../utils/http";
import { getAllLangs } from "../../utils/lang";
import { required } from "../../utils/validators";
import SimpleField from "../Form/Field";
import SelectField from "../Form/SelectField";
import "./style.scss";

const ExportForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();

    return (
        <form className="base-margin export-form" method="post" onSubmit={handleSubmit}>
            <h2 className="title title--medium">{t("admin.export.title")}</h2>
            {error && <div className="errors">{error}</div>}
            <div className="dates">
                <Field
                    component={SimpleField}
                    label={t("admin.export.from")}
                    name="startDate"
                    type="date"
                    validate={required}
                />
                <Field
                    component={SimpleField}
                    label={t("admin.export.to")}
                    name="endDate"
                    type="date"
                    validate={required}
                />
            </div>
            <div className="language">
                <Field
                    component={SelectField}
                    label={t("admin.export.language")}
                    name="language"
                    options={getAllLangs().map(({ keyLang, label }) => ({ value: keyLang, label }))}
                    hasAllOption
                />
            </div>
            <div className="submit-button">
                <button className="button button--black" disabled={!valid || submitting} type="submit">
                    {t("admin.export.button")}
                </button>
            </div>
        </form>
    );
};

ExportForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

const exportFormResponses = async values => {
    try {
        const response = await POST("/api/admin/export", values, { jsonify: false });
        if (!response) {
            throw new SubmissionError({ _error: Errors.serverError });
        }

        const fileName = response.headers.get("Content-disposition").split('"')[1];
        const file = await response.blob();

        return downloadjs(file, fileName);
    } catch (error) {
        throw new SubmissionError({ _error: await error.response.text() });
    }
};

export default reduxForm({
    form: "export-form",
    onSubmit: values => exportFormResponses(values),
})(ExportForm);
