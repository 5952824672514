import PropTypes from 'prop-types';
import React from 'react';
import Main from '../Main';
import './style.scss';

const MainWithStep = ({
    className,
    children,
    footer,
    title,
    subheader,
    subtitle,
    isLarge,
    isFullWidth,
}) => (
    <Main className={`main-steps ${className}`} isFullWidth={isFullWidth} isLarge={isLarge}>
        <div className="main-steps__container">
            {(title || subtitle || subheader)
                ? (
                    <div className="main-steps__header">
                        {subheader && <h4 className="main-steps__subheader">{subheader}</h4>}
                        {title && <h1 className="title">{title}</h1>}
                        {subtitle && <span className="main-steps__subtitle">{subtitle}</span>}
                    </div>
                ) : <div />
            }
            <div className="main-steps__content">{children}</div>
            {footer && <div className="main-steps__footer">{footer}</div>}
        </div>
    </Main>
);

MainWithStep.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    footer: PropTypes.node,
    isFullWidth: PropTypes.bool,
    isLarge: PropTypes.bool,
    subheader: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.string,
};

export default MainWithStep;
