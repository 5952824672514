import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '../../../components/layout/Container';
import Main from '../../../components/layout/Main';
import Panel from '../../../components/layout/Panel';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import { goToResults } from '../../../utils/routes';
import './style.scss';

const StepTransitionGif = () => {
    const push = useLocalizedHistoryPush();
    const { formId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            push(goToResults(formId));
        }, 3000);
    });

    return (
        <Container>
            <Panel />
            <Main>
                <div className="step-transition-gif">
                    <div className="processing">
                        <img alt="transition-gif" src="/img/transition-animation.gif" />
                        <span>{t('results.processing')}</span>
                    </div>
                </div>
            </Main>
        </Container>
    );
};

export default StepTransitionGif;
