import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';

import './style.scss';

const ResultsDescription = ({ onReportDownloadButtonClick, onReportShareButtonClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <h2 className="results__panel__title">{t('results.your_results')}</h2>
            <span className="results__panel__content">{t('results.left_side.description')}</span>
            <Button
                className="button--block results__panel__download-button"
                img="/img/icons/download.svg"
                onClick={onReportDownloadButtonClick}
                text={t('common.download_report')}
            />
            <Button
                className="button--black button--block results__panel__share-button"
                img="/img/icons/share.svg"
                onClick={onReportShareButtonClick}
                text={t('common.share_your_results')}
            />
        </>
    );
};

ResultsDescription.propTypes = {
    onReportDownloadButtonClick: PropTypes.func.isRequired,
    onReportShareButtonClick: PropTypes.func.isRequired,
};

export default ResultsDescription;
