import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { languageSelector } from "../store/slices/language";
import { DEFAULT_LANGUAGE } from "../utils/lang";

const RedirectToLocalizedRoute = () => {
    const { pathname } = useLocation();
    const { language, allowedLanguages } = useSelector(languageSelector);
    const { push } = useHistory();

    // Force DEFAULT_LANGUAGE
    if (!allowedLanguages.includes(language)) {
        push(`/${DEFAULT_LANGUAGE}/account`);

        return null;
    }

    if ("/" === pathname) {
        return <Redirect to={`/${language}`} />;
    }

    return <Redirect to={`/${language}${pathname}`} />;
};

export default RedirectToLocalizedRoute;
