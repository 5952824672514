export const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];
        while (' ' === cookie.charAt(0)) {
            cookie = cookie.substring(1);
        }
        if (0 === cookie.indexOf(name)) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return '';
};

export const setCookie = (name, value, days = 365) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
