import PropTypes from 'prop-types';
import React from 'react';
import Phase from '../../../constants/phase.types';
import { statsType } from '../../propTypes/restitutionTypes';
import BackPage from './BackPage';
import FrontPage from './FrontPage';
import PageIntro1 from './PageIntro1';
import PageIntro2 from './PageIntro2';
import PageLayout from './PageLayout';
import PageProfessionalInterests from './PageProfessionalInterests';
import PageProfiles from './PageProfiles';
import PageResult from './PageResult';
import PageResult2 from './PageResult2';
import PageResume from './PageResume';
import './style.scss';

const Restitution = ({ createdAt, stats, user, phase = Phase.MAXI_MARCO }) => (
    <div className="pdf-layout">
        <FrontPage date={createdAt} user={user} />
        <PageLayout date={createdAt} page={2} user={user}>
            <PageIntro1 phase={phase} />
        </PageLayout>
        <PageLayout date={createdAt} page={3} user={user}>
            <PageIntro2 phase={phase} />
        </PageLayout>
        <PageLayout date={createdAt} page={4} user={user} withoutPadding>
            <PageResult {...stats} user={user} />
        </PageLayout>
        <PageLayout date={createdAt} page={5} user={user} withoutPadding>
            <PageResult2 {...stats} user={user} />
        </PageLayout>
        <PageLayout date={createdAt} page={6} user={user} withoutPadding>
            <PageProfiles />
        </PageLayout>
        {phase === Phase.MAXI_MARCO && (
            <PageProfessionalInterests
                {...stats}
                date={createdAt}
                firstPageIndex={7}
                user={user}
            />
        )}
        <PageLayout date={createdAt} page={phase === Phase.MINI_MARCO ? 7 : 12} user={user}>
            <PageResume {...stats} phase={phase} />
        </PageLayout>

        <PageLayout date={createdAt} page={phase === Phase.MINI_MARCO ? 8 : 13} user={user} backpage withoutPadding>
            <BackPage />
        </PageLayout>
    </div>
);

Restitution.propTypes = {
    createdAt: PropTypes.string.isRequired,
    phase: PropTypes.number,
    stats: statsType,
    user: PropTypes.shape({ name: PropTypes.string.isRequired, surname: PropTypes.string.isRequired, }).isRequired,
};

export default Restitution;
