import { SubmissionError } from "redux-form";
import Errors from "../../../constants/errors.json";
import { PATCH } from "../../utils/http";
import { setLastForm } from "../slices/reconnect";

/**
 * Trigger computation of the psico branches / profiles / major / minor
 */
export const computePiscoStats = (lastFormId, phase) => async dispatch => {
    try {
        const form = await PATCH("/api/personality-form/compute-psico-stats", {
            lastFormId,
            phase,
        });
        dispatch(setLastForm(form));

        return form.stats;
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

/**
 * Narrow down the profiles possibilities to decide the major / minor archetype
 */
export const updateProfilesToDecide = (profiles, lastFormId) => async dispatch => {
    try {
        const form = await PATCH("/api/personality-form/decide-profiles", {
            profiles,
            lastFormId,
        });
        dispatch(setLastForm(form));
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

/**
 * Define the major and minor components of the archetype
 */
export const chooseArchetype = (major, minor, lastFormId) => async dispatch => {
    try {
        const form = await PATCH("/api/personality-form/choose-archetype", {
            major,
            minor,
            lastFormId,
        });
        dispatch(setLastForm(form));
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};
