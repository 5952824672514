import { BadParamsException } from '../api/exceptions/bad-params.exception';
// Legacy
export const P = 'practical';
export const S = 'scientific';
export const I = 'innovative';
export const C = 'conciliator';
export const O = 'organizer';
// proper type
export var PsicoProfile;
(function (PsicoProfile) {
    PsicoProfile["P"] = "practical";
    PsicoProfile["S"] = "scientific";
    PsicoProfile["I"] = "innovative";
    PsicoProfile["C"] = "conciliator";
    PsicoProfile["O"] = "organizer";
})(PsicoProfile || (PsicoProfile = {}));
export const parsePsicoProfileFromStringOrThrow = (str) => {
    switch (str) {
        case 'practical': return PsicoProfile.P;
        case 'scientific': return PsicoProfile.S;
        case 'innovative': return PsicoProfile.I;
        case 'conciliator': return PsicoProfile.C;
        case 'organizer': return PsicoProfile.O;
        default:
            throw new BadParamsException('psico_profiles::parsePsicoProfileFromStringOrThrow');
    }
};
