import React from "react";
import { useDispatch } from "react-redux";
import { setMenuOpen } from "../../../store/slices/menu";

const MenuButton = () => {
    const dispatch = useDispatch();

    return (
        <button onClick={() => dispatch(setMenuOpen(true))} type="button">
            <img alt="Menu" src="/img/icons/burger.svg" />
        </button>
    );
};

export default MenuButton;
