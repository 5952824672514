import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { phaseSelector } from "../../store/slices/exerciseData";
import { getExerciseFirstQuestionNumber } from "../../utils/exercises";

const ExerciseRedirector = ({
    match: {
        params: { step },
    },
}) => {
    const phase = useSelector(phaseSelector);
    const firstExerciseQuestion = getExerciseFirstQuestionNumber(phase, parseInt(step, 10));

    return <Redirect to={`/step/${step}/${firstExerciseQuestion}`} />;
};

ExerciseRedirector.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            step: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default ExerciseRedirector;
