import { C, I, O, P, S } from './psico_profiles';

const getCombinedProfileCode = (major, minor) => {
    // CI/IC
    if ((major === C && minor === I) || (major === I && minor === C)) { return 'CIIC'; }
    // CO/OC
    if ((major === C && minor === O) || (major === O && minor === C)) { return 'COOC'; }
    // CP/PC
    if ((major === C && minor === P) || (major === P && minor === C)) { return 'CPPC'; }
    // CS/SC
    if ((major === C && minor === S) || (major === S && minor === C)) { return 'CSSC'; }
    // IO/OI
    if ((major === I && minor === O) || (major === O && minor === I)) { return 'IOOI'; }
    // IP/PI
    if ((major === I && minor === P) || (major === P && minor === I)) { return 'IPPI'; }
    // IS/SI
    if ((major === I && minor === S) || (major === S && minor === I)) { return 'ISSI'; }
    // OP/PO
    if ((major === O && minor === P) || (major === P && minor === O)) { return 'OPPO'; }
    // SO/OS
    if ((major === O && minor === S) || (major === S && minor === O)) { return 'OSSO'; }
    // PS/SP
    if ((major === P && minor === S) || (major === S && minor === P)) { return 'PSSP'; }

    throw Error('unknown profile');
};

const psicoDescriptions = {
    [`${S}${O}`]: {
        major: 'common.profile.S',
        minor: 'common.profile.O',
        text: 'archetype.SO.description',
        spider_text_male: 'archetype.SO.radar.male',
        spider_text_female: 'archetype.SO.radar.female',
        archetype_male: {
            name: 'Charles Darwin',
            url: 'darwin.jpg',
        },
        archetype_female: {
            name: 'Ada Lovelace',
            url: 'ada-lovelace.jpg',
        },
    },
    [`${O}${S}`]: {
        major: 'common.profile.O',
        minor: 'common.profile.S',
        text: 'archetype.OS.description',
        spider_text_male: 'archetype.OS.radar.male',
        spider_text_female: 'archetype.OS.radar.female',
        archetype_male: {
            name: 'Charles Darwin',
            url: 'darwin.jpg',
        },
        archetype_female: {
            name: 'Ada Lovelace',
            url: 'ada-lovelace.jpg',
        },
    },
    [`${P}${S}`]: {
        major: 'common.profile.P',
        minor: 'common.profile.S',
        text: 'archetype.PS.description',
        spider_text_male: 'archetype.PS.radar.male',
        spider_text_female: 'archetype.PS.radar.female',
        archetype_male: {
            name: 'Leonardo da Vinci',
            url: 'de-vinci.jpg',
        },
        archetype_female: {
            name: 'Valentina Terechkova',
            url: 'terechkova.jpg',
        },
    },
    [`${S}${P}`]: {
        major: 'common.profile.S',
        minor: 'common.profile.P',
        text: 'archetype.SP.description',
        spider_text_male: 'archetype.SP.radar.male',
        spider_text_female: 'archetype.SP.radar.female',
        archetype_male: {
            name: 'Leonardo da Vinci',
            url: 'de-vinci.jpg',
        },
        archetype_female: {
            name: 'Valentina Terechkova',
            url: 'terechkova.jpg',
        },
    },
    [`${S}${I}`]: {
        major: 'common.profile.S',
        minor: 'common.profile.I',
        text: 'archetype.SI.description',
        spider_text_male: 'archetype.SI.radar.male',
        spider_text_female: 'archetype.SI.radar.female',
        archetype_male: {
            name: 'Stephen Hawking',
            url: 'hawking.jpg',
        },
        archetype_female: {
            name: 'Hedy Lamarr',
            url: 'hedy-lamarr.jpg',
        },
    },
    [`${I}${S}`]: {
        major: 'common.profile.I',
        minor: 'common.profile.S',
        text: 'archetype.IS.description',
        spider_text_male: 'archetype.IS.radar.male',
        spider_text_female: 'archetype.IS.radar.female',
        archetype_male: {
            name: 'Stephen Hawking',
            url: 'hawking.jpg',
        },
        archetype_female: {
            name: 'Hedy Lamarr',
            url: 'hedy-lamarr.jpg',
        },
    },
    [`${P}${O}`]: {
        major: 'common.profile.P',
        minor: 'common.profile.O',
        text: 'archetype.PO.description',
        spider_text_male: 'archetype.PO.radar.male',
        spider_text_female: 'archetype.PO.radar.female',
        archetype_male: {
            name: 'Pep Guardiola',
            url: 'guardiola.jpg',
        },
        archetype_female: {
            name: 'Coco Chanel',
            url: 'coco-chanel.jpg',
        },
    },
    [`${O}${P}`]: {
        major: 'common.profile.O',
        minor: 'common.profile.P',
        text: 'archetype.OP.description',
        spider_text_male: 'archetype.OP.radar.male',
        spider_text_female: 'archetype.OP.radar.female',
        archetype_male: {
            name: 'Pep Guardiola',
            url: 'guardiola.jpg',
        },
        archetype_female: {
            name: 'Coco Chanel',
            url: 'coco-chanel.jpg',
        },
    },
    [`${S}${C}`]: {
        major: 'common.profile.S',
        minor: 'common.profile.C',
        text: 'archetype.SC.description',
        spider_text_male: 'archetype.SC.radar.male',
        spider_text_female: 'archetype.SC.radar.female',
        archetype_male: {
            name: 'Mahatma Gandhi',
            url: 'gandhi.jpg',
        },
        archetype_female: {
            name: 'Simone de Beauvoir',
            url: 'de-beauvoir.jpg',
        },
    },
    [`${C}${S}`]: {
        major: 'common.profile.C',
        minor: 'common.profile.S',
        text: 'archetype.CS.description',
        spider_text_male: 'archetype.CS.radar.male',
        spider_text_female: 'archetype.CS.radar.female',
        archetype_male: {
            name: 'Mahatma Gandhi',
            url: 'gandhi.jpg',
        },
        archetype_female: {
            name: 'Simone de Beauvoir',
            url: 'de-beauvoir.jpg',
        },
    },
    [`${O}${I}`]: {
        major: 'common.profile.O',
        minor: 'common.profile.I',
        text: 'archetype.OI.description',
        spider_text_male: 'archetype.OI.radar.male',
        spider_text_female: 'archetype.OI.radar.female',
        archetype_male: {
            name: 'Virgil Abloh',
            url: 'virgil-abloh.jpg',
        },
        archetype_female: {
            name: 'Anna Wintour',
            url: 'anna-wintour.jpg',
        },
    },
    [`${I}${O}`]: {
        major: 'common.profile.I',
        minor: 'common.profile.O',
        text: 'archetype.IO.description',
        spider_text_male: 'archetype.IO.radar.male',
        spider_text_female: 'archetype.IO.radar.female',
        archetype_male: {
            name: 'Virgil Abloh',
            url: 'virgil-abloh.jpg',
        },
        archetype_female: {
            name: 'Anna Wintour',
            url: 'anna-wintour.jpg',
        },
    },
    [`${P}${I}`]: {
        major: 'common.profile.P',
        minor: 'common.profile.I',
        text: 'archetype.PI.description',
        spider_text_male: 'archetype.PI.radar.male',
        spider_text_female: 'archetype.PI.radar.female',
        archetype_male: {
            name: 'Kendrick Lamar',
            url: 'kendrick-lamar.jpg',
        },
        archetype_female: {
            name: 'Frida Kahlo',
            url: 'frida.jpg',
        },
    },
    [`${I}${P}`]: {
        major: 'common.profile.I',
        minor: 'common.profile.P',
        text: 'archetype.IP.description',
        spider_text_male: 'archetype.IP.radar.male',
        spider_text_female: 'archetype.IP.radar.female',
        archetype_male: {
            name: 'Kendrick Lamar',
            url: 'kendrick-lamar.jpg',
        },
        archetype_female: {
            name: 'Frida Kahlo',
            url: 'frida.jpg',
        },
    },
    [`${C}${O}`]: {
        major: 'common.profile.C',
        minor: 'common.profile.O',
        text: 'archetype.CO.description',
        spider_text_male: 'archetype.CO.radar.male',
        spider_text_female: 'archetype.CO.radar.female',
        archetype_male: {
            name: 'Nelson Mandela',
            url: 'mandela.jpg',
        },
        archetype_female: {
            name: 'Emma Watson',
            url: 'emma-watson.jpg',
        },
    },
    [`${O}${C}`]: {
        major: 'common.profile.O',
        minor: 'common.profile.C',
        text: 'archetype.OC.description',
        spider_text_male: 'archetype.OC.radar.male',
        spider_text_female: 'archetype.OC.radar.female',
        archetype_male: {
            name: 'Nelson Mandela',
            url: 'mandela.jpg',
        },
        archetype_female: {
            name: 'Emma Watson',
            url: 'emma-watson.jpg',
        },
    },
    [`${C}${P}`]: {
        major: 'common.profile.C',
        minor: 'common.profile.P',
        text: 'archetype.CP.description',
        spider_text_male: 'archetype.CP.radar.male',
        spider_text_female: 'archetype.CP.radar.female',
        archetype_male: {
            name: 'Keanu Reeves',
            url: 'keanu-reeves.jpg',
        },
        archetype_female: {
            name: 'Delia Akeley',
            url: 'delia-akeley.jpg',
        },
    },
    [`${P}${C}`]: {
        major: 'common.profile.P',
        minor: 'common.profile.C',
        text: 'archetype.PC.description',
        spider_text_male: 'archetype.PC.radar.male',
        spider_text_female: 'archetype.PC.radar.female',
        archetype_male: {
            name: 'Keanu Reeves',
            url: 'keanu-reeves.jpg',
        },
        archetype_female: {
            name: 'Delia Akeley',
            url: 'delia-akeley.jpg',
        },
    },
    [`${I}${C}`]: {
        major: 'common.profile.I',
        minor: 'common.profile.C',
        text: 'archetype.IC.description',
        spider_text_male: 'archetype.IC.radar.male',
        spider_text_female: 'archetype.IC.radar.female',
        archetype_male: {
            name: 'Spike Lee',
            url: 'spike-lee.jpg',
        },
        archetype_female: {
            name: 'Pina Bausch',
            url: 'pina-bausch.jpg',
        },
    },
    [`${C}${I}`]: {
        major: 'common.profile.C',
        minor: 'common.profile.I',
        text: 'archetype.CI.description',
        spider_text_male: 'archetype.CI.radar.male',
        spider_text_female: 'archetype.CI.radar.female',
        archetype_male: {
            name: 'Spike Lee',
            url: 'spike-lee.jpg',
        },
        archetype_female: {
            name: 'Pina Bausch',
            url: 'pina-bausch.jpg',
        },
    },
};

export const getPsidoDescriptions = (major, minor, isDeltaSignificant) => {
    const base = psicoDescriptions[`${major}${minor}`];

    return isDeltaSignificant
        ? base
        : {
            ...base,
            text: `archetype.${getCombinedProfileCode(major, minor)}.description`,
        };
};

export default psicoDescriptions;
