import { reset, SubmissionError } from "redux-form";
import Errors from "../../../constants/errors.json";
import Phase from "../../../constants/phase.types";
import { GET, PATCH, POST } from "../../utils/http";
import { goToPhase } from "../slices/exerciseData";
import { setMenuOpen } from "../slices/menu";
import { deleteLastForm , setLastCompletedForm, setLastForm, setUseLastCompletedForm } from "../slices/reconnect";

export const resetForm = () => dispatch => dispatch(reset("personalityForm"));

/* if true, then use last completed form to populate the current form
otherwise, use last form if there is any */
export const restartFromMiniMarco = () => async (dispatch) => {
    dispatch(setUseLastCompletedForm({ value: true }));
}
export const restartFromAccount = () => async (dispatch) => {
    dispatch(setUseLastCompletedForm({ value: false }));
}

export const getLastForms = () => async dispatch => {
    const { lastForm, lastCompletedForm } = await GET("/api/personality-form/last-forms");
    dispatch(setLastForm(lastForm || null));
    dispatch(setLastCompletedForm(lastCompletedForm || null));
};

export const deleteResponseFromExercise = (lastFormId, exerciseNumber, phase) => async dispatch => {
    try {
        const form = await PATCH("/api/personality-form/delete-responses", {
            exerciseNumber,
            lastFormId,
            phase,
        });
        setLastForm(form);
        dispatch({
            type: "SET_VALUES",
            values: form.responses,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveResponse = (lastFormId, exerciseNumber, questionNumber, value) => async () => {
    try {
        await PATCH("/api/personality-form/exercises", {
            exerciseNumber,
            questionNumber,
            value,
            lastFormId,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveSocioDemoResponse = (values, lastFormId, phase = Phase.MAXI_MARCO) => async () => {
    try {
        PATCH("/api/personality-form/step7", {
            ...values,
            lastFormId,
            phase,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const createForm = () => async dispatch => {
    await POST("/api/personality-form");
    dispatch(goToPhase(Phase.MINI_MARCO));
    dispatch(deleteLastForm());
    dispatch(resetForm());
    dispatch(getLastForms());
    dispatch(setMenuOpen(false));
    dispatch(restartFromAccount());
};
