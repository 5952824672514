import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import useBrowserLanguage from "../hooks/useBrowserLanguage";
import { getUrlLanguage, getUrlWithoutLanguage } from "../hooks/useUrlParams";
import useUserLanguage from "../hooks/useUserLanguage";
import { languageSelector, setAllowedLanguages, setLanguage } from "../store/slices/language";
import { getAllLangs } from "../utils/lang";

const LanguageProvider = ({ children }) => {
    const { pathname } = useLocation();
    const { push } = useHistory();
    const userLanguage = useUserLanguage();
    const browserLanguage = useBrowserLanguage();
    const urlLanguage = getUrlLanguage(pathname);

    const { language } = useSelector(languageSelector);
    const dispatch = useDispatch();

    let initLanguage = browserLanguage;
    if (urlLanguage) {
        initLanguage = urlLanguage;
    }
    if (userLanguage) {
        initLanguage = userLanguage;
    }

    const allowedLanguages = getAllLangs().map(({ keyLang }) => keyLang);

    // Initialisation of the language store
    useEffect(() => {
        dispatch(setAllowedLanguages(allowedLanguages));
        dispatch(setLanguage(initLanguage));
    }, []);

    useEffect(() => {
        if (userLanguage) {
            dispatch(setLanguage(userLanguage));
            if (pathname !== "/") {
                push(`/${userLanguage}/${getUrlWithoutLanguage(language, pathname)}`);
            } else {
                push(`/${userLanguage}`);
            }
        }
    }, [userLanguage]);

    useEffect(() => {
        if (urlLanguage && urlLanguage !== language) {
            dispatch(setLanguage(urlLanguage));
        }
    }, [urlLanguage]);

    if (!language) {
        return null;
    }

    return children;
};

LanguageProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LanguageProvider;
