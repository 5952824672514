import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { AppDataProvider } from './hooks/useAppData';
import LanguageProvider from './Language/LanguageProvider';
import Routes from './Routes';
import store from './store/store';
import './App.scss';

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();

        // Disable fast-tap zoom on mobile
        let lastTouchEnd = 0;
        document.addEventListener('touchend', (event) => {
            const now = (new Date()).getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }

            lastTouchEnd = now;
        }, false);
    }

    componentDidMount() {
        if (window.jtTracker) {
            window.jtTracker.page(window.location.pathname);
        }
    }

    render() {
        const { appData } = this.props;

        return (
            <AppDataProvider appData={appData}>
                <Provider store={store}>
                    <Router history={this.history}>
                        <LanguageProvider>
                            <Routes />
                        </LanguageProvider>
                    </Router>
                </Provider>
            </AppDataProvider>
        );
    }
}

App.propTypes = {
    appData: PropTypes.shape({}).isRequired,
};

export default App;
