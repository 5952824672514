var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ProfessionalBranche } from '../../constants/branches';
import { PsicoProfile } from '../../constants/psico_profiles';
import getTexts from '../../constants/texts';
import enumToObject from './enumToObject';
const initStats = (stats) => (Object.keys(stats).reduce((acc, statKey) => {
    const statName = stats[statKey];
    return Object.assign(Object.assign({}, acc), { [statName]: {
            summedScore: 0,
            answersCount: 0,
        } });
}, {}));
const initialSummedStats = {
    branches: initStats(enumToObject(ProfessionalBranche)),
    profiles: initStats(enumToObject(PsicoProfile)),
};
// Try to get the minor component of the archetype
const computeMinor = (computedProfiles) => {
    const values = Object.values(computedProfiles).sort().reverse();
    if (values[0] === values[1] || values[1] === values[2]) {
        return null;
    }
    return Object.keys(computedProfiles).find((key) => computedProfiles[key] === values[1]);
};
// Try to get the major component of the archetype
const computeMajor = (computedProfiles) => {
    const values = Object.values(computedProfiles).sort().reverse();
    return values[0] !== values[1]
        ? Object.keys(computedProfiles).find((key) => computedProfiles[key] === values[0])
        : null;
};
// Get the PSICO profiles which have the same score when we don't already have a major and a minor
const computeProfilesToDecide = (computedProfiles, major, minor) => {
    // We don't to decide between profiles if we already have the major and minor components of the archetype
    if (major && minor) {
        return [];
    }
    const values = Object.values(computedProfiles).sort().reverse();
    // If we don't have a major, we know the first value is the highest one
    // If we have a major, that means we cannot decide for the minor, so we take the second highest value
    const valueToCompare = null === major ? values[0] : values[1];
    return Object.keys(computedProfiles).filter((key) => computedProfiles[key] === valueToCompare);
};
// Divide the sum of a stat by the number of answers
const computeStats = (stats) => Object.keys(stats).reduce((acc, statKey) => {
    const statData = stats[statKey];
    // should never happen
    if (0 === statData.answersCount) {
        acc[statKey] = 0;
    }
    else {
        acc[statKey] = statData.summedScore / statData.answersCount;
    }
    return acc;
}, {});
/**
 * detect if the difference between major and minor profiles is above a given threshold
 * @param {Object} listOfProfiles list of profiles shaped as {P: number, S:number, I:number, C:number, O:number}
 * @returns {boolean}
 */
export const computeIsDeltaSignificant = (listOfProfiles) => {
    const [major, minor] = Object
        .entries(listOfProfiles)
        .sort((profile1, profile2) => profile2[1] - profile1[1])
        .slice(0, 2);
    return (major[1] - minor[1]) > 1;
};
export const computeIsFullyAnswered = (listOfResponses) => {
    const { roti, step7 } = listOfResponses, filteredResponses = __rest(listOfResponses, ["roti", "step7"]);
    const numberOfResponses = Object
        .values(filteredResponses)
        .map((response) => Object.values(response))
        .flat()
        .length;
    return {
        miniMarcoIsFull: numberOfResponses >= 30,
        marcoIsFull: numberOfResponses >= 139,
    };
};
const computePsicoStats = (phase, responses) => {
    const summedStats = Object.keys(getTexts(phase)).reduce((statsAcc, exerciseNumber) => {
        const acc = Object.assign({}, statsAcc);
        for (let i = 1; i <= phase; i++) {
            getTexts(i)[exerciseNumber].questions.forEach(({ profile, branch, key }) => {
                const responseValue = responses[exerciseNumber][key];
                acc.branches[branch].summedScore += responseValue;
                acc.branches[branch].answersCount++;
                // the majority of questions does not contribute to profile computation
                if (profile) {
                    acc.profiles[profile].summedScore += responseValue;
                    acc.profiles[profile].answersCount++;
                }
            });
        }
        return acc;
    }, JSON.parse(JSON.stringify(initialSummedStats)));
    const computedBranches = computeStats(summedStats.branches);
    const computedProfiles = computeStats(summedStats.profiles);
    const major = computeMajor(computedProfiles);
    const minor = computeMinor(computedProfiles);
    const isDeltaSignificant = computeIsDeltaSignificant(computedProfiles);
    const fullyAnswered = computeIsFullyAnswered(responses);
    return Object.assign(Object.assign({}, fullyAnswered), { branches: computedBranches, isDeltaSignificant,
        major,
        minor, profiles: computedProfiles, profilesToDecide: computeProfilesToDecide(computedProfiles, major, minor) });
};
export default computePsicoStats;
