import qs from "query-string";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { languageSelector } from "../store/slices/language";

const useLocalizedHistoryPush = () => {
    const { language } = useSelector(languageSelector);
    const history = useHistory();

    return url => {
        const baseRoute = `/${language}`;
        const route = url && url !== "/" ? `${baseRoute}${url}` : baseRoute;
        history.push(route);
    };
};

export const useUrlParams = () => {
    const location = useLocation();
    const history = useHistory();

    return urlParams => {
        const currentSearchParams = qs.parse(location.search);
        const newSearchParams = {
            ...currentSearchParams,
            ...urlParams,
        };
        Object.keys(newSearchParams).forEach(key => {
            if ([null, undefined].includes(newSearchParams[key])) {
                delete newSearchParams[key];
            }
        });
        history.push({ search: `?${qs.stringify(newSearchParams)}` });
    };
};

export const getUrlParams = () => {
    const { search } = useLocation();

    return qs.parse(search);
};

export default useLocalizedHistoryPush;
