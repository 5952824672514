import { BadParamsException } from '../api/exceptions/bad-params.exception';
import getExerciseData from './exercise_data';
/**
 * retrieve static information about a step
 * @param {number} step [1-6] index of the step
 * @returns {object} results : complete set of translations for this step
 * @returns {object} results.instruction label for all propositions of the exercise
 * @returns {object} results.stepName title for this step
 * @returns {object} results.topText text displayed in the lateral bar and the instruction popup (1st paragraph)
 * @returns {object} results.botText text displayed in the lateral bar and the instruction popup (2nd paragraph)
 * @returns {object} results.transition translations passed to the transition component
 * @returns {object} results.transition.subTitle title used on the transition
 * @returns {object} results.transition.description content of the transition
 */
function getExerciseText(step) {
    switch (step) {
        case 1:
            return {
                instruction: 'exercise.1.instruction',
                stepName: 'exercise.1.stepName',
                topText: 'exercise.1.topText',
                botText: 'exercise.1.botText',
                transition: {
                    subTitle: 'exercise.1.transition.subTitle',
                    description: 'exercise.1.transition.description',
                },
            };
        case 2:
            return {
                instruction: 'exercise.2.instruction',
                stepName: 'exercise.2.stepName',
                topText: 'exercise.2.topText',
                botText: 'exercise.2.botText',
                transition: {
                    subTitle: 'exercise.2.transition.subTitle',
                    description: 'exercise.2.transition.description',
                },
            };
        case 3:
            return {
                instruction: 'exercise.3.instruction',
                stepName: 'exercise.3.stepName',
                topText: 'exercise.3.topText',
                botText: 'exercise.3.botText',
                transition: {
                    subTitle: 'exercise.3.transition.subTitle',
                    description: 'exercise.3.transition.description',
                },
            };
        case 4:
            return {
                instruction: 'exercise.4.instruction',
                stepName: 'exercise.4.stepName',
                topText: 'exercise.4.topText',
                botText: 'exercise.4.botText',
                transition: {
                    subTitle: 'exercise.4.transition.subTitle',
                    description: 'exercise.4.transition.description',
                },
            };
        case 5:
            return {
                instruction: 'exercise.5.instruction',
                stepName: 'exercise.5.stepName',
                topText: 'exercise.5.topText',
                botText: 'exercise.5.botText',
                transition: {
                    subTitle: 'exercise.5.transition.subTitle',
                    description: 'exercise.5.transition.description',
                },
            };
        case 6:
            return {
                instruction: 'exercise.6.instruction',
                stepName: 'exercise.6.stepName',
                topText: 'exercise.6.topText',
                botText: 'exercise.6.botText',
                transition: {
                    subTitle: 'exercise.6.transition.subTitle',
                    description: 'exercise.6.transition.description',
                    descriptionMini: 'exercise.6.transition.description.mini',
                },
            };
        default:
            throw new BadParamsException(`Step ${step} is not a valid step`);
    }
}
;
/**
 * retrieve all required data for the component Exercise to function
 * @param {number} phase number of the phase
 * @returns {Object} the complete set of questions divided into 6 parts with texts and data
 */
const getTexts = (phase) => ({
    step1: Object.assign(Object.assign({}, getExerciseText(1)), getExerciseData(phase, 1)),
    step2: Object.assign(Object.assign({}, getExerciseText(2)), getExerciseData(phase, 2)),
    step3: Object.assign(Object.assign({}, getExerciseText(3)), getExerciseData(phase, 3)),
    step4: Object.assign(Object.assign({}, getExerciseText(4)), getExerciseData(phase, 4)),
    step5: Object.assign(Object.assign({}, getExerciseText(5)), getExerciseData(phase, 5)),
    step6: Object.assign(Object.assign({}, getExerciseText(6)), getExerciseData(phase, 6)),
});
export default getTexts;
