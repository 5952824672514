import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { STATUS_ADMIN } from "../../constants";
import { languageSelector } from "../store/slices/language";

const CheckLogin = ({ id, status }) => {
    const { language } = useSelector(languageSelector);
    if (id) {
        if (STATUS_ADMIN === status) {
            return <Redirect to={`/${language}/admin`} />;
        }

        return <Redirect to={`/${language}/account`} />;
    }

    return <Redirect to={`/${language}`} />;
};

CheckLogin.propTypes = {
    id: PropTypes.string,
    status: PropTypes.string,
};

const mapStateToProps = ({ auth: { id, status } }) => ({ id, status });

export default connect(mapStateToProps)(CheckLogin);
