import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import psicoProfileTypes from '../../propTypes/psicoProfileTypes';
import './index.scss';

const Archetype = ({ userGender, psicoProfile }) => {
    const archetype = psicoProfile[`archetype_${userGender}`];
    const { t } = useTranslation();

    return (
        <div className="result-block archetype-container">
            <img
                alt="archetype"
                className="archetype"
                src={`/img/portraits/${archetype.url}`}
            />
            <h1 className="archetype-name">
                {t('results.archetype.title')}
                <br />
                <span className="uppercase extra-bold">{archetype.name}</span>
            </h1>
        </div>
    );
};

Archetype.propTypes = {
    psicoProfile: psicoProfileTypes,
    userGender: PropTypes.string.isRequired,
};

export default Archetype;
