import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../utils/routes';
import Modal from '../common/Modal';

const PauseConfirmation = ({ onClose, push }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('navigation.pause.title')}>
            <div className="base-margin">
                <p>{t('navigation.pause.subtitle')}</p>
                <button
                    className="button button--block button--grey base-margin"
                    onClick={onClose}
                    type="button"
                >
                    {t('navigation.pause.cancel')}
                </button>
                <button
                    className="button button--block button--black base-margin"
                    onClick={() => { push(ROUTES.Account); onClose(); }}
                    type="button"
                >
                    {t('navigation.pause.confirm')}
                </button>
            </div>
        </Modal>
    );
};

PauseConfirmation.propTypes = {
    onClose: PropTypes.func,
    push: PropTypes.func,
};

export default PauseConfirmation;
