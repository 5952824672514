import { isEmpty, path } from 'ramda';
import { computeIsFullyAnswered } from '../../api/utils/computePsicoStats';
import getExerciseData from '../../constants/exercise_data';
import Phase from '../../constants/phase.types';
import {
    getExerciseFirstQuestionNumber,
    getExerciseLastQuestionNumber,
    isExerciseFirstQuestion,
    isExerciseLastQuestion,
} from './exercises';
import { ROUTES } from './routes';

function detectLastUncompletedStep(form, phase) {
    let step = 1;
    // for each step
    for (let i = 1; i < 7; i++) {
        // get last question key
        const lastQuestionKey = getExerciseLastQuestionNumber(phase, i);
        // check if there is an answer for this key
        const answerToLastQuestionKey = form.responses[`step${i}`] !== undefined
            ? form.responses[`step${i}`][lastQuestionKey]
            : undefined;
        // if not, we've reach the last uncompleted exercise
        if (answerToLastQuestionKey === undefined) {
            step = i;
            break;
        }
    }

    return step;
}

function detectLastUncompletedQuestion(form, phase, step) {
    const stepQuestions = getExerciseData(phase, step).questions;
    let response = stepQuestions[0].key;

    // for each question
    for (const question of stepQuestions) {
        // check if there is an answer
        const answer = form.responses[`step${step}`] !== undefined
            ? form.responses[`step${step}`][question.key]
            : undefined;
        // if not, this is the last uncompleted question, return its value
        if (answer === undefined) {
            response = question.key;
            break;
        }
    }

    return response;
}

export function goToLastEmptyQuestion(form, phase) {
    // condition of failure TODO

    // get the last uncompleted exercise
    const lastUncompletedStep = detectLastUncompletedStep(form, phase);
    const lastUncompletedQuestion = detectLastUncompletedQuestion(form, phase, lastUncompletedStep);

    return `/step/${lastUncompletedStep}/${lastUncompletedQuestion}`
}

export function previousStepURL({ form, phase, goToPhase }) {
    if (!form
        || !form.responses
        || Object.keys(form.responses).reduce((acc, step) => (
            acc && isEmpty(form.responses[step])), true) // all steps empty
    ) {
        return ROUTES.FormStart;
    }

    let isMiniEndedAndFullMarcoNotEnded = false;
    if (form && form.responses) {
        const isFullyAnswered = computeIsFullyAnswered(form.responses);

        if (isFullyAnswered.miniMarcoIsFull && !isFullyAnswered.marcoIsFull) {
            isMiniEndedAndFullMarcoNotEnded = true;
            if (phase === Phase.MINI_MARCO) {
                goToPhase(Phase.MAXI_MARCO);

                return ROUTES.FormStart;
            }
        }
    }

    // in any phase, if 'step7' is in the answers, then go to results
    // NB: step7 is part of the profiling form
    if (path(['responses', 'step7'], form)) {
        return '/step/7';
    }

    const profilesToDecide = path(['stats', 'profilesToDecide'], form);
    // we answered to all questions but we need to answer more questions to determine the archetype
    if (!isMiniEndedAndFullMarcoNotEnded && profilesToDecide && profilesToDecide.length > 0) {
        return '/step/6/rank';
    }

    // we did not answer all questions, we find the next one
    if (1 === Phase.MINI_MARCO) {
        const { exercise, question } = Object
            .keys(form.responses)
            .filter((step) => !isEmpty(form.responses[step]))
            .filter((step) => step !== 'roti')
            .reduce(({ exercise: accExercise, question: accQuestion }, stepKey) => {
                const stepNumber = parseInt(stepKey.replace('step', ''), 10);
                /*
                    we've answers for the next exercise
                    or we're here because we programmatically went to the next exercise first question
                    (still need to check the last question answered for the exercise)
                */
                if (stepNumber > accExercise || isExerciseFirstQuestion(phase, stepNumber, accQuestion)) {
                    // get the last question answered of the exercise
                    const questionNumber = Object
                        .keys(form.responses[stepKey])
                        .reduce((questionAcc, questionKey) => {
                            const currentQuestionNumber = parseInt(questionKey, 10);
                            if (currentQuestionNumber > questionAcc) {
                                return currentQuestionNumber;
                            }

                            return questionAcc;
                        }, 0);

                    // last question => go to next exercise first question
                    if (isExerciseLastQuestion(phase, stepNumber, questionNumber)) {
                        if (6 === stepNumber) {
                            return {
                                exercise: 6,
                                question: 'transition',
                            };
                        }
                        const nextExerciseNumber = stepNumber + 1;

                        return {
                            exercise: nextExerciseNumber,
                            question: getExerciseFirstQuestionNumber(phase, nextExerciseNumber),
                        };
                    }

                    // not the last question => we stay on the same exercise but go to the next question
                    return {
                        exercise: stepNumber,
                        question: questionNumber + 1,
                    };
                }

                // we finally found the next exercise and the next question
                return {
                    exercise: accExercise,
                    question: accQuestion,
                };
            }, { exercise: 1, question: 1 });

        return `/step/${exercise}/${question}`;
    }

    if (2 === Phase.MAXI_MARCO) {
        let exercise = 1; // step number
        let question = 1;

        Object.keys(form.responses).filter((step) => 'roti' !== step && 'step7' !== step).every((stepKey) => {
            const stepNumber = parseInt(stepKey.replace('step', ''), 10);

            // get the last question answered of the exercise
            const lastQuestionNumber = Object
                .keys(form.responses[stepKey])
                .reduce((questionAcc, questionKey) => {
                    const currentQuestionNumber = parseInt(questionKey, 10);
                    if (currentQuestionNumber > questionAcc) {
                        return currentQuestionNumber;
                    }

                    return questionAcc;
                }, 0);

            // last question => go to next exercise first question
            if (isExerciseLastQuestion(phase, stepNumber, lastQuestionNumber)) {
                if (6 === stepNumber) {
                    exercise = 6;
                    question = 'transition';
                }
                const nextExerciseNumber = stepNumber + 1;

                exercise = nextExerciseNumber;
                question = getExerciseFirstQuestionNumber(phase, nextExerciseNumber);

                return true;
            }

            if (lastQuestionNumber > question) {
                question = lastQuestionNumber + 1;
            }

            return true;
        });

        return `/step/${exercise}/${question}`;
    }

    return ROUTES.FormStart;
}

export default previousStepURL;
