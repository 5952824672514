import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
    name: "menu",
    initialState: { isOpen: false },
    reducers: {
        setMenuOpen: (state, action) => ({
            isOpen: action.payload,
        }),
    },
});

export const isMenuOpenSelector = state => state.menu.isOpen;

export const { setMenuOpen } = menuSlice.actions;

export default menuSlice.reducer;
