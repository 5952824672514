import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { STATUS_ADMIN } from "../../../../constants";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import { createForm } from "../../../store/actions";
import { detectPhase, goToPhase } from "../../../store/slices/exerciseData";
import { setMenuOpen } from "../../../store/slices/menu";
import { goToLastEmptyQuestion } from "../../../utils/menu";
import { goToResults, ROUTES } from "../../../utils/routes";
import LanguageSwitcher from "../../common/LanguageSwitcher";
import "./style.scss";

const Menu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const {
        auth: { status },
        user,
        reconnect: { lastForm, lastCompletedForm },
    } = useSelector(state => state);
    const { pathname } = useLocation();

    const startNewForm = async () => {
        await dispatch(createForm());
        push(ROUTES.FormStart);
    };

    const resumeToLastForm = async () => {
        const lastFormPhase = detectPhase(lastForm);
        await dispatch(goToPhase(lastFormPhase))
        push(goToLastEmptyQuestion(lastForm, lastFormPhase));
    }

    const displayResumeToForm = () => {
        // if there is no current form, return false
        if (!(lastForm && !lastForm.completedAt)) return false;

        // hide this button during a test
        if (pathname.includes('/step/')) return false;

        return true;
    }

    return (
        <div className="menu">
            <h1 className="menu__title">{t("account.welcome", { username: user && user.surname })}</h1>
            {user && status !== STATUS_ADMIN && <LanguageSwitcher color="black" />}
            <ul className="menu__inner">
                {status !== STATUS_ADMIN && (
                    <>
                        <li className="menu__link">
                            <button
                                onClick={() => {
                                    dispatch(setMenuOpen(false));
                                    push(ROUTES.Account);
                                }}
                                type="button"
                            >
                                <img alt={t("menu.account")} src="/img/icons/house.svg" />
                                {t("menu.account")}
                            </button>
                        </li>
                        {displayResumeToForm() && (
                            <li className="menu__link">
                                <Link
                                    onClick={resumeToLastForm}
                                    title={t("menu.continue")}
                                    to={goToLastEmptyQuestion(lastForm, detectPhase(lastForm))}
                                >
                                    <img alt={t("menu.continueLastForm")} src="/img/icons/pen.svg" />
                                    {t("menu.continueLastForm")}
                                </Link>
                            </li>
                        )}
                        <li className="menu__link">
                            <button onClick={startNewForm} type="button">
                                <img alt="" src="/img/icons/history.svg" />
                                {t("account.card.restart")}
                            </button>
                        </li>
                        {lastCompletedForm && lastCompletedForm.miniCompletedAt && (
                            <li className="menu__link">
                                <Link
                                    onClick={() => {
                                        dispatch(setMenuOpen(false));
                                    }}
                                    title={t("menu.results")}
                                    to={goToResults(lastCompletedForm.id)}
                                >
                                    <img alt={t("menu.lastResults")} src="/img/icons/statistic.svg" />
                                    {t("menu.lastResults")}
                                </Link>
                            </li>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Menu;
