import PropTypes from "prop-types";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Phase from "../../../../constants/phase.types";
import psicoDescriptions from "../../../../constants/psico_profile_descriptions";
import { useAppData } from "../../../hooks/useAppData";
import { majorType, minorType } from "../../../propTypes/restitutionTypes";
import { languageSelector } from "../../../store/slices/language";
import "./style.scss";

const PageResume = ({ major, minor, phase = Phase.MAXI_MARCO }) => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const { FREYA_URL, HESTER_URL, VASCO_V2_URL } = useAppData();
    const psicoProfile = psicoDescriptions[`${major}${minor}`];

    return (
        <div className="page-resume">
            <div className="page-resume__shape">
                <img alt="Page soft skill shape" src="/img/pdf/page-resume-shape.svg" />
            </div>
            <h2 className="pdf__title">{t("pdf.pageResume.title")}</h2>
            <p>
                <Trans
                    i18nKey="pdf.pageResume.text1"
                    values={{ profile: `${t(psicoProfile.major)} ${t(psicoProfile.minor)}` }}
                />
            </p>
            <p>
                <Trans i18nKey="pdf.pageResume.text2" />
            </p>
            <p>
                <Trans i18nKey="pdf.pageResume.text3" />
            </p>
            <div className="page-resume__next-step">
                <h2 className="pdf__title">{t("pdf.pageResume.nextStep")}</h2>
                <p>
                    <Trans i18nKey="pdf.pageResume.nextStep.text1" />
                </p>
                {phase !== Phase.MINI_MARCO && (
                    <p>
                        <Trans i18nKey="pdf.pageResume.nextStep.text2" />
                    </p>
                )}
                <p>
                    <Trans i18nKey="pdf.pageResume.nextStep.text3" />
                </p>
                <ul>
                    {"fr" === language && phase === Phase.MINI_MARCO && (
                        <li
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t("pdf.pageResume.nextStep.text3.mini", { VASCO_V2_URL }),
                            }}
                        />
                    )}
                    {"fr" === language && (
                        <li
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t("pdf.pageResume.nextStep.text3.vasco", { VASCO_V2_URL }),
                            }}
                        />
                    )}
                    <li
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: t("pdf.pageResume.nextStep.text3.freya", { FREYA_URL }),
                        }}
                    />
                    {"fr" === language && (
                        <li
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t("pdf.pageResume.nextStep.text3.hester", { HESTER_URL }),
                            }}
                        />
                    )}
                </ul>
                <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t("pdf.pageResume.nextStep.text4") }}
                />
                <p>
                    <Trans i18nKey="pdf.pageResume.nextStep.text5" />
                </p>
            </div>
        </div>
    );
};

PageResume.propTypes = {
    major: majorType,
    minor: minorType,
    phase: PropTypes.number,
};

export default PageResume;
