import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lastForm: undefined,
    lastCompletedForm: undefined,
    useLastCompletedForm: false, // true to restart form from results, false to restart form last form if any
};

export const reconnectSlice = createSlice({
    name: "reconnect",
    initialState,
    reducers: {
        setLastForm: (state, action) => ({
            ...state,
            lastForm: action.payload,
        }),
        deleteLastForm: () => initialState,
        setLastCompletedForm: (state, action) => ({
            ...state,
            lastCompletedForm: action.payload,
        }),
        setUseLastCompletedForm: (state, action) => ({
            ...state,
            useLastCompletedForm: action.payload.value,
        }),
    },
});

export const { deleteLastForm, setLastForm, setLastCompletedForm, setUseLastCompletedForm } = reconnectSlice.actions;

export default reconnectSlice.reducer;
