import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DescriptionExercise from '../DescriptionExercise';
import './style.scss';

const NB_STEP = 6;

const generateStep = ({ step, currentStep, phase }) => {
    const hiddenDescription = step !== currentStep;

    return (
        <div key={`step-${step}`} className={classnames('step-list__step', { 'step-list__step--hidden': hiddenDescription })}>
            <DescriptionExercise hiddenDescription={hiddenDescription} phase={phase} step={step} type="short" />
        </div>
    );
};

generateStep.propTypes = {
    currentStep: PropTypes.string.isRequired,
    phase: PropTypes.number.isRequired,
    step: PropTypes.string.isRequired,
};

const StepList = ({ phase, step }) => {
    const steps = [];
    for (let i = 1; i <= NB_STEP; i++) {
        steps.push(generateStep({ step: i.toString(), currentStep: step.toString(), phase }));
    }

    return <div className="step-list">{steps}</div>;
};

StepList.propTypes = {
    phase: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
};

export default StepList;
