import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LIKERT_MAX_VALUE } from '../../../constants';
import getBranchesRanking from '../../utils/branches';
import './index.scss';

/**
 * build the right translation key according to branch and intereste
 * @param {string} branchKey identifier of the branch
 * @param {number} value % of interest in the branch
 * @returns {string} translation key
 */
const getBranchDescriptionTranslationKey = (branchKey, value) => {
    if (value >= 75) return `common.branch.${branchKey}.description.strong`;
    if (value >= 35) return `common.branch.${branchKey}.description.medium`;

    return `common.branch.${branchKey}.description.low`;
}

const MatchingBranchesRanking = ({
    branches,
    className = '',
    displayValue = false,
    legend = true,
    nbBranches,
    withTitle = false,
}) => {
    const { t } = useTranslation();
    const ranking = getBranchesRanking(branches, nbBranches);

    return (
        <div
            className={classnames('motivation-engines', 'result-block', className)}
            style={{ padding: '36px' }}
        >
            {withTitle && <h1>{t('results.matching_jobs_ranking.title')}</h1>}
            {Object.keys(ranking).map((branchKey) => {
                const percent = ranking[branchKey] / LIKERT_MAX_VALUE * 100;

                return (
                    <div key={branchKey}>
                        <div className="branch-title">
                            {t(`common.branch.${branchKey}`)}
                            {displayValue && (
                                <span className="branch-value">{` - ${percent.toFixed(2)}%`}</span>
                            )}
                        </div>
                        <div className="horizontal-bar">
                            <div
                                className="fill-bar"
                                style={{ width: `${percent}%` }}
                            />
                        </div>
                        {
                            !legend && (
                                <p className='pdf__text--justify pdf__branch-description'>
                                    {t(getBranchDescriptionTranslationKey(branchKey, percent))}
                                </p>
                            )
                        }
                    </div>
                );
            })}
            {legend && (
                <div className="legend">
                    <div style={{ whiteSpace: !nbBranches ? 'nowrap' : 'normal' }}>
                        <Trans i18nKey="results.matching_jobs_ranking.legend.lower_scale" />
                    </div>
                    <div
                        className="legend__lower-scale-text"
                        style={{ whiteSpace: !nbBranches ? 'nowrap' : 'normal' }}
                    >
                        <Trans i18nKey="results.matching_jobs_ranking.legend.upper_scale" />
                    </div>
                </div>
            )}
        </div>
    );
};

MatchingBranchesRanking.propTypes = {
    branches: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    displayValue: PropTypes.bool,
    legend: PropTypes.bool,
    nbBranches: PropTypes.number,
    withTitle: PropTypes.bool,
};

export default MatchingBranchesRanking;
