import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import { Field, reduxForm } from 'redux-form';
import {
    AGE_CAT,
    DIPLOMA_LIST,
    GENDER_LIST,
    JOBS_LIST,
    REASONS_LIST,
    ROTI_QUESTIONS,
    STATUS_LIST,
} from '../../utils/constants';
import ChoiceField from '../Form/ChoiceField';
import RotiQuestion from '../Form/RotiQuestion';
import SelectField from '../Form/SelectField';
import './style.scss';

const validate = (values) => {
    const errors = {
        reasons: {},
        roti: {},
    };

    if (!values.gender) {
        errors.gender = 'Required';
    }
    if (!values.status) {
        errors.status = 'Required';
    }
    if (!values.diploma) {
        errors.diploma = 'Required';
    }
    if ((!values.reasons)
        || (values.reasons && (values.reasons && !Object.values(values.reasons).includes(true)))) {
        errors.reasons.knowledge = 'Required';
    }

    return errors;
};

const SliderComponent = (onChange, value) => {
    const renderTooltip = (props, state) => (
        <div {...props}>
            <div className="slider-form__value">{AGE_CAT[state.valueNow].toString()}</div>
        </div>
    );


    return (
        <ReactSlider
            className="slider-form"
            defaultValue={value}
            markClassName="slider-form__mark"
            max={5}
            min={0}
            onAfterChange={(v) => onChange(v)}
            renderThumb={(props, state) => renderTooltip(props, state)}
            thumbClassName="slider-form__thumb"
            trackClassName="slider-form__track"
        />
    );
}

const ProfilingForm = ({ invalid, handleSubmit }) => {
    const { t } = useTranslation();

    return (
        <form className="profiling-form" method="post" onSubmit={handleSubmit}>
            <h2 className="subheading">{t('sociodemo.you')}</h2>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.age.title')}</p>
                <Field
                    component={({ input: { onChange, value } }) => (
                        SliderComponent(onChange, value)
                    )}
                    defaultValue="1"
                    format={null}
                    id="age"
                    name="age"
                />
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.gender.title')}</p>
                <div className="choicefield__wrapper">
                    {GENDER_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`gender-${i}`}
                            label={t(status.name)}
                            name="gender"
                            options={GENDER_LIST}
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.status.title')}</p>
                <div className="choicefield__wrapper">
                    {STATUS_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`status-${i}`}
                            label={t(status.name)}
                            name="status"
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <p className="label">{t('sociodemo.field.diploma.title')}</p>
                <div className="choicefield__wrapper">
                    {DIPLOMA_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`diploma-${i}`}
                            label={t(status.name)}
                            name="diploma"
                            type="radio"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <p className="label"><Trans i18nKey="sociodemo.field.reason.title" /></p>
                <div className="choicefield__wrapper">
                    {REASONS_LIST.map((status, i) => (
                        <Field
                            key={status.key}
                            component={ChoiceField}
                            id={`reason-${i}`}
                            label={t(status.name)}
                            name={`reasons.${status.key}`}
                            type="checkbox"
                            value={status.key}
                        />
                    ))}
                </div>
            </div>
            <div className="form-group">
                <Field
                    component={SelectField}
                    label={t('sociodemo.field.job.title')}
                    name="job"
                    options={JOBS_LIST.map(({ key, name }) => ({ value: key, label: t(name) }))}
                    placeholder={t('sociodemo.job.placeholder')}
                />
            </div>
            <div className="form-group">
                <h2 className="subheading">{t('sociodemo.satisfaction')}</h2>
                {ROTI_QUESTIONS.map((rotiQuestion) => (
                    <RotiQuestion key={rotiQuestion} label={rotiQuestion} />
                ))}
            </div>
            <button
                className="button button--black button--center button--block-mobile"
                disabled={invalid}
                type="submit"
            >
                {t('sociodemo.submit')}
            </button>
        </form>
    );
};

ProfilingForm.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
};

export default connect(
    ({ reconnect: { lastForm: { responses: { step7 } } = { responses: { step7: {}, roti: {} } } } }) => (
        { initialValues: { ...step7 } }
    ),
)(reduxForm({
    form: 'profilingForm',
    validate,
})(ProfilingForm));
