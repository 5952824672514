import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getExerciseInfos } from '../../../utils/exercises';
import './style.scss';

const DescriptionExercise = ({ step, type = 'short', hiddenDescription = false, phase }) => {
    const { t } = useTranslation();
    const { topText, botText, stepName } = useMemo(() => getExerciseInfos(phase, step), [step]);

    return (
        <div className={`description-exercise description-exercise__${type}`}>
            <h3>{`${step}- ${t(stepName)}`}</h3>
            { !hiddenDescription && (
                <>
                    <p><Trans i18nKey={topText} /></p>
                    <p><Trans i18nKey={botText} /></p>
                </>
            )}
        </div>
    );
};

DescriptionExercise.propTypes = {
    hiddenDescription: PropTypes.bool,
    phase: PropTypes.number.isRequired,
    step: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['long', 'short']),
};

export default DescriptionExercise;
