import { extractLangFromIso639, isAValidLanguage } from '../utils/lang';

const useBrowserLanguage = () => {
    const userLang = navigator.language || navigator.userLanguage;
    const lang = extractLangFromIso639(userLang);

    return isAValidLanguage(lang) ? lang : 'fr';
};

export default useBrowserLanguage;
