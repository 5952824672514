import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/layout/Container';
import MainWithBackButton from '../../components/layout/MainWithBackButton';
import Panel from '../../components/layout/Panel';
import LegalPanelContent from '../../components/PanelDescription/LegalPanelContent';

const LegalNotices = () => {
    const { t } = useTranslation();

    return (
        <Container bgColor="white">
            <Panel><LegalPanelContent /></Panel>
            <MainWithBackButton title={t('common.legalNotices.title')}>
                <div
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: t('common.legalNotices.content') }}
                    className="static-page"
                />
            </MainWithBackButton>
        </Container>
    );
};

export default LegalNotices;
