export const goToResults = (formId) => `/results/${formId}`;

export const goToProcessing = (formId) => `/processing/${formId}`;

export const ROUTES = {
    Account: '/account',
    FormStart: '/step/1',
    Homepage: '/',
    Logout: '/logout',
};
