import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/common/Button";
import Container from "../../../components/layout/Container";
import Panel from "../../../components/layout/Panel";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import { getLastForms } from "../../../store/actions";
import { goToResults, ROUTES } from "../../../utils/routes";
import "./style.scss";

const LastResults = () => {
    const {
        reconnect: { lastCompletedForm },
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getLastForms());
    }, []);

    // if a value is found, redirects to the correct result
    if (lastCompletedForm) {
        push(goToResults(lastCompletedForm.id));

        return null;
    }

    // otherwise, lastCompletedForm is undefined because the getLastForms request isn't complete
    if (lastCompletedForm !== null) {
        return <p>loading...</p>;
    }

    // if lastCompletedForm is null, there is no last completed forms so redirects the user to its account
    return (
        <Container>
            <Panel />
            <div className="last-results">
                <img alt="" src="/img/results/top.svg" />
                <div className="last-results__container">
                    <div className="last-results__no-results">
                        <p>{t("results.empty.text")}</p>
                        <Button
                            className="button--block last-results__back-to-home"
                            onClick={() => push(ROUTES.Account)}
                            text={t("results.empty.button")}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default LastResults;
