import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from '../HomeFooter';
import './style.scss';

const ErrorPage = ({ children, content, title }) => {
    const { t } = useTranslation();

    return (
        <div className="error-page__container">
            <header className="error-page__header">
                <Link
                    title={t('general.account')}
                    to="/"
                >
                    <img
                        alt="Logo"
                        src="/img/logos/marco-black.svg"
                        width="107"
                    />
                </Link>
            </header>
            <main className="error-page__content__wrapper">
                <div className="error-page__content">
                    <div className="error-page__content__text">
                        <h1>{title}</h1>
                        <p>{content}</p>
                        {children}
                    </div>
                    <img alt="" aria-hidden="true" src="/img/error.svg" />
                </div>
            </main>
            <Footer />
        </div>
    );
};

ErrorPage.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default ErrorPage;
