import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getPsidoDescriptions } from '../../../../constants/psico_profile_descriptions';
import { majorType, minorType, profilesType } from '../../../propTypes/restitutionTypes';
import RadarChart from '../../RadarChart';
import Colored from '../Colored';
import './style.scss';

const PageResult = ({ user, major, minor, profiles, isDeltaSignificant }) => {
    const { t } = useTranslation();

    const data = [
        { label: t('common.profile.P'), value: profiles.P },
        { label: t('common.profile.S'), value: profiles.S },
        { label: t('common.profile.I'), value: profiles.I },
        { label: t('common.profile.C'), value: profiles.C },
        { label: t('common.profile.O'), value: profiles.O },
    ];

    const psicoProfile = getPsidoDescriptions(major, minor, isDeltaSignificant);

    return (
        <div className="pdf-result">
            <Colored color="grey" withPadding>
                <h2 className="pdf__title">{t('pdf.pageResults.title')}</h2>
                <p className="pdf__text--justify"><Trans i18nKey="pdf.pageResults.description" /></p>
            </Colored>
            <div className="pdf-result__content">
                <p>{t('pdf.pageResults.text')}</p>
                <span className="pdf-result__profile">{`${t(psicoProfile.major)} ${t(psicoProfile.minor)}`}</span>
                <p>{t(psicoProfile.text)}</p>
                <p>{t(psicoProfile[`spider_text_${user.gender}`])}</p>
                <RadarChart
                    data={data}
                    psicoProfile={psicoProfile}
                />
            </div>
            <div className="pdf-result__shape">
                <img
                    alt="Result shape"
                    src="/img/pdf/pdf-result-shape.svg"
                />
            </div>
        </div>
    );
};

PageResult.propTypes = {
    isDeltaSignificant: PropTypes.bool,
    major: majorType,
    minor: minorType,
    profiles: profilesType,
    user: PropTypes.shape({
        gender: PropTypes.string.isRequired,
    }),
};

export default PageResult;
