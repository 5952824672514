import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Restitution from '../../components/Restitution';
import { GET } from '../../utils/http';

function numberWithTwoDigits(n) {
    return n < 10 ? `0${n}` : n;
}

function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${numberWithTwoDigits(day)}  /  ${numberWithTwoDigits(month + 1)}  /  ${year}`;
}

const RestitutionWrapper = ({
    match: { params: { formId, phase } },
    location: { search },
}) => {
    const [restitutionData, setRestitutionData] = useState(null);
    const query = queryString.parse(search);

    useEffect(() => {
        const getPdf = async () => {
            try {
                const response = await GET(`/api/pdf/restitution/${formId}?security=${query.security}`);
                setRestitutionData(response);
            } catch (e) {
                console.error(e);
            }
        };

        getPdf();
    }, []);

    if (null === restitutionData) { return <span>No data</span>; }

    return <Restitution {...restitutionData} createdAt={formatDate(new Date(restitutionData.createdAt))} phase={parseInt(phase, 10)} />;
};

RestitutionWrapper.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string.isRequired,
            phase: PropTypes.string.isRequired,
        }),
    }),
};

export default RestitutionWrapper;
