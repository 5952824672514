import { createSlice } from "@reduxjs/toolkit";
import { SubmissionError } from "redux-form";
import { DELETE, GET, PATCH } from "../../utils/http";

export const userSlice = createSlice({
    name: "user",
    initialState: null,
    reducers: {
        cleanUser: () => null,
        setUser: (state, action) => action.payload,
        setUserLanguage: (state, action) => ({
            ...state,
            language: action.payload,
        }),
    },
});

export const { cleanUser, setUser, setUserLanguage } = userSlice.actions;

export const getUser = () => async dispatch => {
    try {
        const user = await GET("/api/user");
        dispatch(setUser(user));
    } catch (e) {
        throw new SubmissionError({ _error: "error.server.unknown" });
    }
};

export const updateUserLanguage = values => async dispatch => {
    try {
        await PATCH("/api/user/language", values, { jsonify: false });
        dispatch(setUserLanguage(values.language));
    } catch (e) {
        throw new SubmissionError({ _error: "error.server.unknown" });
    }
};

export const deleteAccount = () => async () => {
    try {
        const response = await DELETE("/api/user/");

        if (response.success) {
            return true;
        }

        return null;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export default userSlice.reducer;
