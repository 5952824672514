import React from 'react';
import ApexCharts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import psicoProfileTypes from '../../propTypes/psicoProfileTypes';
import radarDataTypes from '../../propTypes/radarDataTypes';
import COLORS from '../../styles/_variables.scss';
import './style.scss';

const prepareChartConfig = (data) => {
    const labels = [];
    const values = [];

    if (data && data.length !== 0) {
        for (const d of data) {
            labels.push(d.label);
            values.push(d.value);
        }
    }

    // rotate values (in DE version, the text overlaps the next block)
    const firstLabel = labels.shift();
    labels.push(firstLabel);
    const firstValues = values.shift();
    values.push(firstValues);

    return {
        options: {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                style: {
                    colors: [COLORS.blackTwo],
                },
                textAnchor: 'middle',
            },
            fill: {
                colors: [COLORS.purple],
            },
            labels,
            markers: {
                size: 0,
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: COLORS.radarChartBorder,
                    },
                },
            },
            stroke: {
                colors: [COLORS.purple],
                dashArray: 0,
                show: true,
                width: 3,
            },
            yaxis: {
                show: false,
            },
        },
        series: [{
            name: 'serie1',
            data: values,
        }],
    };
};

const RadarChart = ({ data, psicoProfile: { major, minor } }) => {
    const { options: { chart, ...optionsRest }, series } = prepareChartConfig(data);
    const { t } = useTranslation();
    const translatedMinor = t(minor);
    const translatedMajor = t(major);

    // highlight major and minor components on the radar with text bold
    const highlightArchetype = () => {
        const [labels] = document.getElementsByClassName('apexcharts-datalabels');
        const { children } = labels;
        Object.keys(children).forEach((childKey) => {
            const child = children[childKey];
            const val = child.textContent;

            if (val === translatedMajor || val === translatedMinor) {
                const style = child.getAttribute('style');
                child.setAttribute('style', `font-weight: bold;${style}`);
            }
        });
    };

    const optionsWithHighlightOnRender = {
        ...optionsRest,
        chart: {
            ...chart,
            events: {
                // re-highlight the archetype components each time the radar is rendered
                updated: highlightArchetype,
            },
        },
    };

    return (
        <div className="radar-chart-container">
            <div className="radar-chart">
                <ApexCharts
                    height="300"
                    options={optionsWithHighlightOnRender}
                    series={series}
                    type="radar"
                />
            </div>
        </div>
    );
};

RadarChart.propTypes = {
    data: radarDataTypes,
    psicoProfile: psicoProfileTypes,
};

export default RadarChart;
