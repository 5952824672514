/* eslint-disable no-restricted-syntax, guard-for-in, react/no-array-index-key */
import PropTypes from 'prop-types';
import { splitAt, splitEvery } from 'ramda';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { branchesType } from '../../../propTypes/restitutionTypes';
import MatchingBranchesRanking from '../../MatchingBranchesRanking';
import PageLayout from '../PageLayout';
import './style.scss';

const elementForFirstPage = 4;
const elementByPage = 6;

export const arrayToObject = (array) => {
    const result = {};

    for (const [key, value] of array) {
        result[key] = value;
    }

    return result;
}

export const splitOnPage = (branches) => {
    const array = [];
    // push couple [key,value] in an array to sort their values as the key order cannot be trusted
    // result looks like [[key1, value1], [key2, value2], ...]
    for (const key in branches) {
        array.push([key, branches[key]]);
    }

    // sort array according to the value in DESC order
    array.sort((b1, b2) => b1[1] < b2[1] ? 1 : -1);

    // split global array into 2 parts
    // [0] is the first page, containing a given number of elements
    // [1] is the rest of the array
    const splittedBranches = splitAt(
        elementForFirstPage,
        array,
    );

    // build the result by creating an array looking like
    // [0] is an array containing the [key,value] couples of the first page
    // [1] is an array containing the [key,value] couples of the second page
    // [X] etc for every page, as long as the global array reaches its end
    const branchesByPage = [
        splittedBranches[0],
        ...splitEvery(
            elementByPage,
            splittedBranches[1],
        ),
    ];

    // the rest of the application uses branches as an array {branch1: score, branche2: score, ...}
    // so we convert the result for each page into an object
    return branchesByPage.map(branch => arrayToObject(branch));
};

const PageProfessionalInterests = ({ branches, date, firstPageIndex, user }) => {
    const { t } = useTranslation();
    const branchesByPage = splitOnPage(branches);
    const firstPageBranches = branchesByPage.shift();

    let index = firstPageIndex;
    const getNextPageIndex = () => index++;

    return (
        <div className="page-professional-interests">
            <PageLayout date={date} page={getNextPageIndex()} user={user}>
                <h2 className="pdf__title">{t('pdf.pageProfessionalInterests.title')}</h2>
                <p className="pdf__text--justify">
                    <Trans i18nKey="pdf.pageProfessionalInterests.description1" />
                </p>
                <p className="pdf__text--justify pdf__p">
                    <Trans i18nKey="pdf.pageProfessionalInterests.description2" />
                </p>
                <p className="pdf__text--justify pdf__p">
                    <Trans i18nKey="pdf.pageProfessionalInterests.description3" />
                </p>
                <MatchingBranchesRanking
                    branches={firstPageBranches}
                    legend={false}
                    displayValue
                    showAll
                />
            </PageLayout>
            { branchesByPage.map((pageBranches, order) => (
                <PageLayout key={order} date={date} page={getNextPageIndex()} user={user}>
                    <MatchingBranchesRanking
                        branches={pageBranches}
                        legend={false}
                        displayValue
                        showAll
                    />
                </PageLayout>
            ))}
        </div>
    );
};

PageProfessionalInterests.propTypes = {
    branches: branchesType,
    date: PropTypes.string,
    firstPageIndex: PropTypes.number.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
};

export default PageProfessionalInterests;
