import { getExerciseInfos } from './exercises';

/**
 *
 * @param {number} phase
 * @param {number} exerciseNumber
 * @returns
 */
export const getExerciseProgression = (phase, exerciseNumber) => getExerciseInfos(phase, exerciseNumber).progression;

/**
 * Compute the batch position and progression of a question for a given exercise
 * For:
 * - batch progression exercise of: [11, 11, 11, 10, 10, 10]
 * - question number: 1 | 34 | 56 | 64
 * We respectively get:
 * progressionPosition: 0 | 3 | 5 | 5
 * completedPartsCount: 0 | 0 | 2 | 10
 * @param {number} phaseNumber
 * @param {number} exerciseNumber
 * @param {number} questionNumber
 * @returns {{
 *  progressionPosition: batch index the question belongs to,
 *  completedPartsCount: number of questions already answered for the question batch
 * }}
 */
export const getQuestionProgression = (phase, exerciseNumber, questionNumber) => {
    const exerciseInfos = getExerciseInfos(phase, exerciseNumber);
    const { progression } = exerciseInfos;
    const { questions } = exerciseInfos;
    const questionPosition = questions.findIndex(({ key }) => key === questionNumber);
    let accumulatedPartsCount = 0;
    let questionPositionRelativeToBatch = null;
    // key of the progression array the question belongs to
    let progressionPosition;
    progression.forEach((partsPerLot, index) => {
        // we already found what we needed
        if (questionPositionRelativeToBatch !== null) {
            return;
        }
        accumulatedPartsCount += partsPerLot;
        // we add 1 as question position starts from 0
        if (questionPosition + 1 <= accumulatedPartsCount) {
            progressionPosition = index;
            // partsPerLot if this is the first batch
            const diff = (accumulatedPartsCount - partsPerLot) || partsPerLot;
            questionPositionRelativeToBatch = questionPosition % diff;
        }
    });

    return {
        completedPartsCount: questionPositionRelativeToBatch,
        progressionPosition,
    };
};
