import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import LocalizedRoutes from "./Language/LocalizedRoutes";
import RedirectToLocalizedRoute from "./Language/RedirectToLocalizedRoute";
import { languageSelector } from "./store/slices/language";

const Routes = () => {
    const { allowedLanguages } = useSelector(languageSelector);

    return (
        <Switch>
            <Route path={`/:lang(${allowedLanguages.join("|")})`}>
                <LocalizedRoutes />
            </Route>
            <Route path="*">
                <RedirectToLocalizedRoute />
            </Route>
        </Switch>
    );
};

export default Routes;
