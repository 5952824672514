import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAppData } from "../../../hooks/useAppData";
import useWindowSize from "../../../hooks/useWindowSize";
import { languageSelector } from "../../../store/slices/language";
import LanguageSwitcher from "../../common/LanguageSwitcher";
import "./style.scss";

const HomeFooter = () => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const isMobile = "xs" === useWindowSize().size;
    const { INNOVATION_SITE_URL } = useAppData();

    return (
        <footer className="footer" role="contentinfo">
            <div className="footer__content">
                {isMobile ? (
                    <>
                        <img alt="Logo Job Teaser" src="/img/logos/jobteaser-white.svg" />
                        <LanguageSwitcher className="language-switcher__small" />
                        <div className="footer__separator" />
                    </>
                ) : undefined}
                <div className="footer__content--left">
                    <a
                        className="footer__link"
                        href={INNOVATION_SITE_URL}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={t("common.footer.innovation_site")}
                    >
                        {t("common.footer.innovation_site")}
                    </a>
                    <Link
                        className="footer__link"
                        title={t("common.footer.legal_mentions")}
                        to={`/${language}/legalnotices`}
                    >
                        {t("common.footer.legal_mentions")}
                    </Link>
                    <Link className="footer__link" title={t("common.footer.terms_of_service")} to={`/${language}/tos`}>
                        {t("common.footer.terms_of_service")}
                    </Link>
                    <Link
                        className="footer__link"
                        title={t("common.footer.privacy_policy")}
                        to={`/${language}/confidentiality`}
                    >
                        {t("common.footer.privacy_policy")}
                    </Link>
                </div>
                {isMobile ? <div className="footer__separator" /> : undefined}
                <div>
                    <span>{t("common.footer.copyright", { actualYear: new Date().getFullYear() })}</span>
                    {isMobile ? <br /> : " - "}
                    <span>{t("common.footer.copyrightDetails")}</span>
                </div>
            </div>
        </footer>
    );
};

export default HomeFooter;
