import React from 'react';
import { useTranslation } from 'react-i18next';
import { majorType, minorType } from '../../../propTypes/restitutionTypes';
import Colored from '../Colored';

const getStandOutTextKey = (major, minor) => [major[0].toUpperCase(), minor[0].toUpperCase()].sort().join('');

const PageResult = ({ major, minor }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf-result">
            <Colored color="grey" withPadding>
                <h2 className="pdf__title">{t('pdf.pageResults.standOut.title')}</h2>
            </Colored>
            <div className="pdf-result__content">
                <p
                    /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{
                        __html: t(`pdf.pageResults.standOut.${getStandOutTextKey(major, minor)}`)
                    }}
                />
            </div>
        </div>
    );
};

PageResult.propTypes = {
    major: majorType,
    minor: minorType,
};

export default PageResult;
