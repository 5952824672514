import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { ROTI_ANSWERS } from '../../../utils/constants';
import ChoiceField from '../ChoiceField';
import './style.scss';

const RotiQuestion = ({ label }) => {
    const { t } = useTranslation();

    return (
        <div className="form-group">
            <p className="roti__label">{t(`sociodemo.${label}.label`)}</p>
            <div className="choicefield__wrapper roti__choicefield-wrapper">
                {ROTI_ANSWERS.map((roti) => (
                    <Field
                        key={roti.toString()}
                        component={ChoiceField}
                        id={`${label}-${roti}`}
                        label={roti.toString()}
                        name={`roti.${label}`}
                        normalize={(value) => parseInt(value, 10)}
                        styleLabel="rounded"
                        type="radio"
                        value={roti}
                    />
                ))}
            </div>
            <div className="roti__choicefield-label-wrapper">
                <span>{t('sociodemo.answer.left')}</span>
                <span>{t('sociodemo.answer.middle')}</span>
                <span>{t('sociodemo.answer.right')}</span>
            </div>
        </div>
    );
};

RotiQuestion.propTypes = {
    label: PropTypes.string.isRequired,
};

export default RotiQuestion;
