export const ANIMALS = 'animals'; // Animaux
export const ARCHITECTURE_INTERIOR_DESIGN = 'architecture_interior_design'; // Architecture - Aménagement d'Intérieur
export const ARTS_CRAFTS = 'arts_crafts'; // Artisanat - Métiers d'Art et de Culture
export const BANK_FINANCE_AUDIT_MANAGEMENT = 'bank_finance_audit_management'; // Métiers de la Finance
export const BUILDINGS_PUBLIC_WORKS = 'buildings_public_works'; // Bâtiments Travaux Publics - Matériaux
export const CARE_AESTHETICS_HAIRDRESSING = 'care_aesthetics_hairdressing'; // Soins - Esthétique - Coiffure
export const COMMUNICATION_INFORMATION = 'communication_information'; // Communication - Information
// Sciences Informatiques, Numériques et Technologie
export const COMPUTER_SCIENCE_ELECTRONICS = 'computer_science_electronics';
export const CULTURE_SHOW = 'culture_show'; // Métiers du Spectacle
export const DEFENSE_SECURITY_RESCUE = 'defense_security_rescue'; // Défense - Sécurité - Secours
export const DIGITAL_MULTIMEDIA_AUDIOVISUAL = 'digital_multimedia_audiovisual'; // Multimédia - Audiovisuel
export const EDUCATION_TRAINING = 'education_training'; // Enseignement - Formation
export const ENVIRONMENT_NATURE_CLEANING = 'environment_nature_cleaning'; // Environnement - Nature - Nettoyage
export const FOOD_FOOD_INDUSTRY = 'food_food_industry'; // Agriculture - Agroalimentaire
export const HEALTH = 'health'; // Santé
export const HOTEL_CATERING_TOURISM = 'hotel_catering_tourism'; // Hôtellerie - Restauration - Tourisme
export const HUMANITIES = 'humanities'; // Sciences Humaines et Sociales
export const HUMAN_RESSOURCES = 'human_ressources'; // Ressources Humaines
export const LAW_POLITICS = 'law_politics'; // Droit - Sciences Politiques
export const MECHANICS_MAINTENANCE = 'mechanics_maintenance'; // Mécanique - Maintenance
export const PUBLISHING_PRINTING_BOOKS = 'publishing_printing_books'; // Lettres - Edition
export const SCIENCES_MATHEMATICS_PHYSICS = 'sciences_mathematics_physics'; // Sciences Physiques et Chimiques
export const SECRETARIAT_RECEPTION = 'secretariat_reception'; // Administratif - Secrétariat - Accueil
export const SOCIAL_PERSONAL_SERVICES = 'social_personal_services'; // Social - Humanitaire
export const SPORT_ANIMATION = 'sport_animation'; // Sport - Animation
export const TRADE_REAL_ESTATE_INSURANCE = 'trade_real_estate_insurance'; // Commerce - Immobilier
export const TRANSPORTATION_LOGISTICS = 'transportation_logistics'; // Transport et Logistique
export var ProfessionalBranche;
(function (ProfessionalBranche) {
    ProfessionalBranche["ANIMALS"] = "animals";
    ProfessionalBranche["ARCHITECTURE_INTERIOR_DESIGN"] = "architecture_interior_design";
    ProfessionalBranche["ARTS_CRAFTS"] = "arts_crafts";
    ProfessionalBranche["BANK_FINANCE_AUDIT_MANAGEMENT"] = "bank_finance_audit_management";
    ProfessionalBranche["BUILDINGS_PUBLIC_WORKS"] = "buildings_public_works";
    ProfessionalBranche["CARE_AESTHETICS_HAIRDRESSING"] = "care_aesthetics_hairdressing";
    ProfessionalBranche["COMMUNICATION_INFORMATION"] = "communication_information";
    ProfessionalBranche["COMPUTER_SCIENCE_ELECTRONICS"] = "computer_science_electronics";
    ProfessionalBranche["CULTURE_SHOW"] = "culture_show";
    ProfessionalBranche["DEFENSE_SECURITY_RESCUE"] = "defense_security_rescue";
    ProfessionalBranche["DIGITAL_MULTIMEDIA_AUDIOVISUAL"] = "digital_multimedia_audiovisual";
    ProfessionalBranche["EDUCATION_TRAINING"] = "education_training";
    ProfessionalBranche["ENVIRONMENT_NATURE_CLEANING"] = "environment_nature_cleaning";
    ProfessionalBranche["FOOD_FOOD_INDUSTRY"] = "food_food_industry";
    ProfessionalBranche["HEALTH"] = "health";
    ProfessionalBranche["HOTEL_CATERING_TOURISM"] = "hotel_catering_tourism";
    ProfessionalBranche["HUMANITIES"] = "humanities";
    ProfessionalBranche["HUMAN_RESSOURCES"] = "human_ressources";
    ProfessionalBranche["LAW_POLITICS"] = "law_politics";
    ProfessionalBranche["MECHANICS_MAINTENANCE"] = "mechanics_maintenance";
    ProfessionalBranche["PUBLISHING_PRINTING_BOOKS"] = "publishing_printing_books";
    ProfessionalBranche["SCIENCES_MATHEMATICS_PHYSICS"] = "sciences_mathematics_physics";
    ProfessionalBranche["SECRETARIAT_RECEPTION"] = "secretariat_reception";
    ProfessionalBranche["SOCIAL_PERSONAL_SERVICES"] = "social_personal_services";
    ProfessionalBranche["SPORT_ANIMATION"] = "sport_animation";
    ProfessionalBranche["TRADE_REAL_ESTATE_INSURANCE"] = "trade_real_estate_insurance";
    ProfessionalBranche["TRANSPORTATION_LOGISTICS"] = "transportation_logistics";
})(ProfessionalBranche || (ProfessionalBranche = {}));
