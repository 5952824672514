import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

const requireForm = (ComposedComponent) => {
    const Form = reduxForm({
        form: 'personalityForm',
        enableReinitialize: true,
    })(ComposedComponent);

    class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                formId: null,
                loading: true,
                initialValues: null,
            };
        }

        async componentDidMount() {
            let form = {
                id: null,
                responses: {
                    step1: {},
                    step2: {},
                    step3: {},
                    step4: {},
                    step5: {},
                    step6: {},
                    step7: {
                        age: 1,
                    },
                    roti: {
                        satisfaction: null,
                        match: null,
                        length: null,
                        reflect: null,
                        orientation: null,
                    },
                },
            };

            const { lastForm, lastCompletedForm, useLastCompletedForm } = this.props;
            // select source of initial values between last form and lastCompletedForm using the flag useLastCompletedForm
            if (lastForm && !useLastCompletedForm) {
                // restart from account (~ a resume to last form)
                form = mergeRight(form, lastForm);
            }
            if (lastCompletedForm && useLastCompletedForm) {
                // restart from results (~ continue last completed mini form)
                form = mergeRight(form, lastCompletedForm);
            }

            this.setState({
                loading: false,
                formId: form.id,
                initialValues: form.responses,
            });
        }

        render() {
            const { loading, formId, initialValues } = this.state;

            if (loading) {
                return null;
            }

            return (
                <Form formId={formId} initialValues={initialValues} {...this.props} />
            );
        }
    }

    FormWrapper.propTypes = {
        lastCompletedForm: PropTypes.shape({}),
        lastForm: PropTypes.shape({}),
        useLastCompletedForm: PropTypes.bool,
    };

    const mapStateToProps = ({ reconnect: {
        lastForm,
        lastCompletedForm,
        useLastCompletedForm,
    } }) => ({
        lastForm,
        lastCompletedForm,
        useLastCompletedForm,
    });

    return connect(mapStateToProps)(FormWrapper);
};

export default requireForm;
