import getTexts from '../../constants/texts';

const exerciseKeyword = 'step';

export const getExerciseInfos = (phase, exerciseNumber) => {
    if (0 === exerciseNumber) {
        return {
            stepName: undefined,
            transition: {},
        };
    }

    return getTexts(phase)[`${exerciseKeyword}${exerciseNumber}`];
};

export const getExerciseFirstQuestionNumber = (phase, exerciseNumber) => (
    getExerciseInfos(phase, exerciseNumber).questions[0].key
);

export const getExerciseLastQuestionNumber = (phase, exerciseNumber) => {
    const { questions } = getExerciseInfos(phase, exerciseNumber);

    return questions[questions.length - 1].key;
};

/**
 *
 * @param {number} phase
 * @param {number} exerciseNumber
 * @param {number} questionNumber
 * @returns {boolean}
 */
export const isExerciseFirstQuestion = (phase, exerciseNumber, questionNumber) => (
    getExerciseFirstQuestionNumber(phase, exerciseNumber) === questionNumber
);

export const isExerciseLastQuestion = (phase, exerciseNumber, questionNumber) => (
    getExerciseLastQuestionNumber(phase, exerciseNumber) === questionNumber
);

/**
 *
 * @param {number} phase
 * @param {number} exerciseNumber
 * @param {number} questionNumber
 * @returns {boolean}
 */
export const isFinalQuestion = (phase, exerciseNumber, questionNumber) => (
    Object.keys(getTexts(phase)).length === exerciseNumber
    && isExerciseLastQuestion(phase, exerciseNumber, questionNumber)
);

export const getQuestion = (phase, exerciseNumber, questionNumber) => {
    const { questions } = getExerciseInfos(phase, exerciseNumber);

    return questions.find(({ key }) => key === questionNumber);
};
