import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const Smiley = ({ value, isSelected, onSelect }) => (
    <button
        className="smiley-btn"
        disabled={isSelected}
        onClick={() => { onSelect(value); }}
        onKeyPress={(event) => { if ('1' === event.key) { onSelect(value); } }}
        type="button"
    >
        <img
            alt={`smiley-${value}`}
            className={isSelected ? 'hidden' : ''}
            src={`/img/smileys-bw/${value}.svg`}
        />
        <img
            alt={`smiley-${value}`}
            className={!isSelected ? 'hidden' : ''}
            src={`/img/smileys-colored/${value}.svg`}
        />
    </button>
);

Smiley.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
};

export default Smiley;
