import { ProfessionalBranche } from './branches';
import { PsicoProfile } from './psico_profiles';
function getExercise1Data(step) {
    switch (step) {
        case 1: return {
            progression: [2, 2, 2, 1],
            questions: [
                { question: 'question.6', key: 1, branch: ProfessionalBranche.SCIENCES_MATHEMATICS_PHYSICS, profile: PsicoProfile.S },
                { question: 'question.12', key: 2, branch: ProfessionalBranche.SCIENCES_MATHEMATICS_PHYSICS, profile: PsicoProfile.S },
                { question: 'question.25', key: 3, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT, profile: PsicoProfile.O },
                { question: 'question.26', key: 4, branch: ProfessionalBranche.ARTS_CRAFTS, profile: PsicoProfile.I },
                { question: 'question.27', key: 5, branch: ProfessionalBranche.MECHANICS_MAINTENANCE, profile: PsicoProfile.P },
                { question: 'question.28', key: 6, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES, profile: PsicoProfile.C },
                { question: 'question.36', key: 7, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS, profile: PsicoProfile.S },
            ],
        };
        case 2: return {
            progression: [1, 1, 1, 1, 1, 1],
            questions: [
                { question: 'question.41', key: 8, branch: ProfessionalBranche.ARTS_CRAFTS, profile: PsicoProfile.I },
                { question: 'question.48', key: 9, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS, profile: PsicoProfile.S },
                { question: 'question.51', key: 10, branch: ProfessionalBranche.BUILDINGS_PUBLIC_WORKS, profile: PsicoProfile.P },
                { question: 'question.52', key: 11, branch: ProfessionalBranche.COMMUNICATION_INFORMATION, profile: PsicoProfile.O },
                { question: 'question.53', key: 12, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES, profile: PsicoProfile.C },
                { question: 'question.54', key: 13, branch: ProfessionalBranche.HEALTH, profile: PsicoProfile.C },
            ],
        };
        case 3: return {
            progression: [1, 1, 1],
            questions: [
                { question: 'question.55', key: 14, branch: ProfessionalBranche.MECHANICS_MAINTENANCE, profile: PsicoProfile.P },
                { question: 'question.57', key: 15, branch: ProfessionalBranche.ARTS_CRAFTS, profile: PsicoProfile.I },
                { question: 'question.64', key: 16, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT, profile: PsicoProfile.O },
            ],
        };
        case 4: return {
            progression: [1, 1, 1, 1, 1],
            questions: [
                { question: 'question.69', key: 17, branch: ProfessionalBranche.ARTS_CRAFTS, profile: PsicoProfile.I },
                { question: 'question.71', key: 18, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT, profile: PsicoProfile.O },
                { question: 'question.75', key: 19, branch: ProfessionalBranche.BUILDINGS_PUBLIC_WORKS, profile: PsicoProfile.P },
                { question: 'question.79', key: 20, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES, profile: PsicoProfile.C },
                { question: 'question.95', key: 21, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY, profile: PsicoProfile.P },
            ],
        };
        case 5: return {
            progression: [1, 1, 1, 1, 1],
            questions: [
                { question: 'question.103', key: 22, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES, profile: PsicoProfile.C },
                { question: 'question.104', key: 23, branch: ProfessionalBranche.HEALTH, profile: PsicoProfile.S },
                { question: 'question.113', key: 24, branch: ProfessionalBranche.PUBLISHING_PRINTING_BOOKS, profile: PsicoProfile.I },
                { question: 'question.118', key: 25, branch: ProfessionalBranche.TRADE_REAL_ESTATE_INSURANCE, profile: PsicoProfile.O },
                { question: 'question.126', key: 26, branch: ProfessionalBranche.BUILDINGS_PUBLIC_WORKS, profile: PsicoProfile.P },
            ],
        };
        case 6: return {
            progression: [1, 1, 1, 1],
            questions: [
                { question: 'question.156', key: 27, branch: ProfessionalBranche.HEALTH, profile: PsicoProfile.S },
                { question: 'question.160', key: 28, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES, profile: PsicoProfile.C },
                { question: 'question.161', key: 29, branch: ProfessionalBranche.PUBLISHING_PRINTING_BOOKS, profile: PsicoProfile.I },
                { question: 'question.167', key: 30, branch: ProfessionalBranche.TRADE_REAL_ESTATE_INSURANCE, profile: PsicoProfile.O },
            ],
        };
        default:
            throw Error('unknown step');
    }
}
function getExercise2Data(step) {
    switch (step) {
        case 1: return {
            progression: [4, 4, 4, 4, 4, 3],
            questions: [
                { question: 'question.2', key: 31, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY },
                { question: 'question.3', key: 32, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY },
                { question: 'question.4', key: 33, branch: ProfessionalBranche.SCIENCES_MATHEMATICS_PHYSICS },
                { question: 'question.5', key: 34, branch: ProfessionalBranche.TRADE_REAL_ESTATE_INSURANCE },
                { question: 'question.7', key: 35, branch: ProfessionalBranche.COMMUNICATION_INFORMATION },
                { question: 'question.8', key: 36, branch: ProfessionalBranche.SCIENCES_MATHEMATICS_PHYSICS },
                { question: 'question.10', key: 37, branch: ProfessionalBranche.TRANSPORTATION_LOGISTICS },
                { question: 'question.14', key: 38, branch: ProfessionalBranche.SCIENCES_MATHEMATICS_PHYSICS },
                { question: 'question.17', key: 39, branch: ProfessionalBranche.HEALTH },
                { question: 'question.19', key: 40, branch: ProfessionalBranche.DIGITAL_MULTIMEDIA_AUDIOVISUAL },
                { question: 'question.20', key: 41, branch: ProfessionalBranche.HUMANITIES },
                { question: 'question.21', key: 42, branch: ProfessionalBranche.ENVIRONMENT_NATURE_CLEANING },
                { question: 'question.22', key: 43, branch: ProfessionalBranche.PUBLISHING_PRINTING_BOOKS },
                { question: 'question.23', key: 44, branch: ProfessionalBranche.ANIMALS },
                { question: 'question.24', key: 45, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS },
                { question: 'question.29', key: 46, branch: ProfessionalBranche.MECHANICS_MAINTENANCE },
                { question: 'question.30', key: 47, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.31', key: 48, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT },
                { question: 'question.32', key: 49, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY },
                { question: 'question.33', key: 50, branch: ProfessionalBranche.EDUCATION_TRAINING },
                { question: 'question.34', key: 51, branch: ProfessionalBranche.SECRETARIAT_RECEPTION },
                { question: 'question.35', key: 52, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS },
                { question: 'question.38', key: 53, branch: ProfessionalBranche.ANIMALS },
            ],
        };
        case 2: return {
            progression: [2, 2, 2, 2],
            questions: [
                { question: 'question.40', key: 54, branch: ProfessionalBranche.LAW_POLITICS },
                { question: 'question.42', key: 55, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY },
                { question: 'question.43', key: 56, branch: ProfessionalBranche.CULTURE_SHOW },
                { question: 'question.44', key: 57, branch: ProfessionalBranche.ARCHITECTURE_INTERIOR_DESIGN },
                { question: 'question.45', key: 58, branch: ProfessionalBranche.HUMANITIES },
                { question: 'question.46', key: 59, branch: ProfessionalBranche.PUBLISHING_PRINTING_BOOKS },
                { question: 'question.47', key: 60, branch: ProfessionalBranche.HUMAN_RESSOURCES },
                { question: 'question.49', key: 61, branch: ProfessionalBranche.SPORT_ANIMATION },
            ],
        };
        case 3: return {
            progression: [1, 1, 1, 1, 1],
            questions: [
                { question: 'question.56', key: 62, branch: ProfessionalBranche.ARTS_CRAFTS },
                { question: 'question.59', key: 63, branch: ProfessionalBranche.CULTURE_SHOW },
                { question: 'question.60', key: 64, branch: ProfessionalBranche.ANIMALS },
                { question: 'question.65', key: 65, branch: ProfessionalBranche.ENVIRONMENT_NATURE_CLEANING },
                { question: 'question.68', key: 66, branch: ProfessionalBranche.PUBLISHING_PRINTING_BOOKS },
            ],
        };
        case 4: return {
            progression: [3, 3, 3, 3, 3, 3],
            questions: [
                { question: 'question.70', key: 67, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.74', key: 68, branch: ProfessionalBranche.LAW_POLITICS },
                { question: 'question.76', key: 69, branch: ProfessionalBranche.TRADE_REAL_ESTATE_INSURANCE },
                { question: 'question.78', key: 70, branch: ProfessionalBranche.HEALTH },
                { question: 'question.81', key: 71, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.83', key: 72, branch: ProfessionalBranche.CULTURE_SHOW },
                { question: 'question.84', key: 73, branch: ProfessionalBranche.MECHANICS_MAINTENANCE },
                { question: 'question.85', key: 74, branch: ProfessionalBranche.SPORT_ANIMATION },
                { question: 'question.86', key: 75, branch: ProfessionalBranche.SPORT_ANIMATION },
                { question: 'question.87', key: 76, branch: ProfessionalBranche.LAW_POLITICS },
                { question: 'question.88', key: 77, branch: ProfessionalBranche.HUMAN_RESSOURCES },
                { question: 'question.89', key: 78, branch: ProfessionalBranche.SECRETARIAT_RECEPTION },
                { question: 'question.90', key: 79, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS },
                { question: 'question.91', key: 80, branch: ProfessionalBranche.CARE_AESTHETICS_HAIRDRESSING },
                { question: 'question.92', key: 81, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.93', key: 82, branch: ProfessionalBranche.CULTURE_SHOW },
                { question: 'question.94', key: 83, branch: ProfessionalBranche.SOCIAL_PERSONAL_SERVICES },
                { question: 'question.96', key: 84, branch: ProfessionalBranche.TRANSPORTATION_LOGISTICS },
            ],
        };
        case 5: return {
            progression: [8, 8, 8, 8, 8, 7],
            questions: [
                { question: 'question.97', key: 85, branch: ProfessionalBranche.HEALTH },
                { question: 'question.98', key: 86, branch: ProfessionalBranche.DIGITAL_MULTIMEDIA_AUDIOVISUAL },
                { question: 'question.99', key: 87, branch: ProfessionalBranche.HUMANITIES },
                { question: 'question.100', key: 88, branch: ProfessionalBranche.DIGITAL_MULTIMEDIA_AUDIOVISUAL },
                { question: 'question.101', key: 89, branch: ProfessionalBranche.ARCHITECTURE_INTERIOR_DESIGN },
                { question: 'question.102', key: 90, branch: ProfessionalBranche.COMMUNICATION_INFORMATION },
                { question: 'question.105', key: 91, branch: ProfessionalBranche.BUILDINGS_PUBLIC_WORKS },
                { question: 'question.107', key: 92, branch: ProfessionalBranche.HUMAN_RESSOURCES },
                { question: 'question.108', key: 93, branch: ProfessionalBranche.EDUCATION_TRAINING },
                { question: 'question.109', key: 94, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.110', key: 95, branch: ProfessionalBranche.HUMANITIES },
                { question: 'question.111', key: 96, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.112', key: 97, branch: ProfessionalBranche.LAW_POLITICS },
                { question: 'question.115', key: 98, branch: ProfessionalBranche.ANIMALS },
                { question: 'question.116', key: 99, branch: ProfessionalBranche.EDUCATION_TRAINING },
                { question: 'question.117', key: 100, branch: ProfessionalBranche.DIGITAL_MULTIMEDIA_AUDIOVISUAL },
                { question: 'question.119', key: 101, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT },
                { question: 'question.120', key: 102, branch: ProfessionalBranche.COMPUTER_SCIENCE_ELECTRONICS },
                { question: 'question.122', key: 103, branch: ProfessionalBranche.LAW_POLITICS },
                { question: 'question.123', key: 104, branch: ProfessionalBranche.SECRETARIAT_RECEPTION },
                { question: 'question.125', key: 105, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT },
                { question: 'question.127', key: 106, branch: ProfessionalBranche.HEALTH },
                { question: 'question.128', key: 107, branch: ProfessionalBranche.BANK_FINANCE_AUDIT_MANAGEMENT },
                { question: 'question.129', key: 108, branch: ProfessionalBranche.ARCHITECTURE_INTERIOR_DESIGN },
                { question: 'question.130', key: 109, branch: ProfessionalBranche.TRANSPORTATION_LOGISTICS },
                { question: 'question.131', key: 110, branch: ProfessionalBranche.MECHANICS_MAINTENANCE },
                { question: 'question.132', key: 111, branch: ProfessionalBranche.ENVIRONMENT_NATURE_CLEANING },
                { question: 'question.133', key: 112, branch: ProfessionalBranche.TRANSPORTATION_LOGISTICS },
                { question: 'question.134', key: 113, branch: ProfessionalBranche.TRADE_REAL_ESTATE_INSURANCE },
                { question: 'question.135', key: 114, branch: ProfessionalBranche.FOOD_FOOD_INDUSTRY },
                { question: 'question.136', key: 115, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.138', key: 116, branch: ProfessionalBranche.HEALTH },
                { question: 'question.139', key: 117, branch: ProfessionalBranche.BUILDINGS_PUBLIC_WORKS },
                { question: 'question.140', key: 118, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.141', key: 119, branch: ProfessionalBranche.MECHANICS_MAINTENANCE },
                { question: 'question.142', key: 120, branch: ProfessionalBranche.HUMAN_RESSOURCES },
                { question: 'question.145', key: 121, branch: ProfessionalBranche.TRANSPORTATION_LOGISTICS },
                { question: 'question.146', key: 122, branch: ProfessionalBranche.COMMUNICATION_INFORMATION },
                { question: 'question.147', key: 123, branch: ProfessionalBranche.EDUCATION_TRAINING },
                { question: 'question.148', key: 124, branch: ProfessionalBranche.ENVIRONMENT_NATURE_CLEANING },
                { question: 'question.149', key: 125, branch: ProfessionalBranche.CARE_AESTHETICS_HAIRDRESSING },
                { question: 'question.150', key: 126, branch: ProfessionalBranche.CARE_AESTHETICS_HAIRDRESSING },
                { question: 'question.151', key: 127, branch: ProfessionalBranche.SECRETARIAT_RECEPTION },
                { question: 'question.152', key: 128, branch: ProfessionalBranche.SPORT_ANIMATION },
                { question: 'question.153', key: 129, branch: ProfessionalBranche.CARE_AESTHETICS_HAIRDRESSING },
                { question: 'question.154', key: 130, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.155', key: 131, branch: ProfessionalBranche.SECRETARIAT_RECEPTION },
            ],
        };
        case 6: return {
            progression: [2, 2, 2, 2],
            questions: [
                { question: 'question.157', key: 132, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.159', key: 133, branch: ProfessionalBranche.EDUCATION_TRAINING },
                { question: 'question.162', key: 134, branch: ProfessionalBranche.ARCHITECTURE_INTERIOR_DESIGN },
                { question: 'question.164', key: 135, branch: ProfessionalBranche.ANIMALS },
                { question: 'question.165', key: 136, branch: ProfessionalBranche.DEFENSE_SECURITY_RESCUE },
                { question: 'question.166', key: 137, branch: ProfessionalBranche.HOTEL_CATERING_TOURISM },
                { question: 'question.168', key: 138, branch: ProfessionalBranche.CULTURE_SHOW },
                { question: 'question.169', key: 139, branch: ProfessionalBranche.ENVIRONMENT_NATURE_CLEANING },
            ],
        };
        default:
            throw Error('unknown step');
    }
}
/**
 *
 * @param {number} phase [1-2] index of the phase
 * @param {number} step [1-6] index of the step currently working
 * @returns {object} data all variable data for a step
 * @returns {number[]} data.progression array of progression during the step (used fot progression bar)
 * @returns {Question[]} data.questions array of questions, with question as
 * @returns {string} data.questions.question translation key of the question label
 * @returns {number} data.questions.key id of the question (also the ordre)
 * @returns {string} data.questions.branch identifier of the branch related to the question
 * @returns {string | undefined} data.questions.profile if relevant, the associated profile letter
 */
function getExerciseData(phase, step) {
    if (1 === phase) {
        return getExercise1Data(step);
    }
    if (2 === phase) {
        return getExercise2Data(step);
    }
    console.error('trying to read phase', phase);
    throw Error('exercise_data::getExerciseData unknown exercise'); // TODO::throw domain-significant error
}
export default getExerciseData;
