import PropTypes from "prop-types";
import { path } from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setLastForm } from "../store/slices/reconnect";
import { GET } from "../utils/http";

const requireLastFormId = ComposedComponent => {
    // TODO(AGT)::BURN THIS TO THE GROUND THX
    class FormWrapper extends Component {
        async componentDidMount() {
            const { lastFormId, setLastFormAction } = this.props;
            if (!lastFormId) {
                const { form } = await GET("/api/personality-form/last-forms");
                setLastFormAction(form);
            }
        }

        render() {
            const { lastFormId } = this.props;

            return <ComposedComponent lastFormId={lastFormId} {...this.props} />;
        }
    }

    FormWrapper.propTypes = {
        lastFormId: PropTypes.string,
        setLastFormAction: PropTypes.func.isRequired,
    };

    const mapStateToProps = ({ reconnect }) => ({ lastFormId: path(["lastForm", "id"], reconnect) });

    const mapDispatchToProps = { setLastFormAction: setLastForm };

    return connect(mapStateToProps, mapDispatchToProps)(FormWrapper);
};

export default requireLastFormId;
