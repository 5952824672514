import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../i18n";

export const languageSlice = createSlice({
    name: "language",
    initialState: {
        language: null,
        allowedLanguages: [],
    },
    reducers: {
        setLanguage: (state, action) => ({
            ...state,
            language: action.payload,
        }),
        setAllowedLanguages: (state, action) => ({
            ...state,
            allowedLanguages: action.payload,
        }),
    },
});

export const languageSelector = state => state.language;

export const { setAllowedLanguages } = languageSlice.actions;

export const setLanguage = newLanguage => async (dispatch, getState) => {
    const { allowedLanguages } = getState().language;
    if (allowedLanguages.includes(newLanguage)) {
        i18n.changeLanguage(newLanguage);
    }
    dispatch(languageSlice.actions.setLanguage(newLanguage));
};

export default languageSlice.reducer;
