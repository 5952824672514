import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { STATUS_ADMIN } from "../../../constants";
import Button from "../../components/common/Button";
import Spinner from "../../components/common/Spinner";
import ExportForm from "../../components/ExportForm";
import SelectField from "../../components/Form/SelectField";
import Container from "../../components/layout/Container";
import Main from "../../components/layout/Main";
import MainWithCard from "../../components/layout/MainWithCard";
import Panel from "../../components/layout/Panel";
import { useAppData } from "../../hooks/useAppData";
import { languageSelector } from "../../store/slices/language";
import { GET } from "../../utils/http";
import { getAllLangs } from "../../utils/lang";
import "./style.scss";

const Admin = () => {
    const { status } = useSelector(state => state.auth);
    const [{ nbClients, nbClientsLastSevenDays, nbCompletedForms, nbCompletedFormsLastSevenDays }, setStats] = useState(
        {
            nbClients: 0,
            nbClientsLastSevenDays: 0,
            nbCompletedForms: 0,
            nbCompletedFormsLastSevenDays: 0,
        }
    );
    const [statLanguage, setStatLanguage] = useState("");
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const languages = getAllLangs();
    const history = useHistory();
    const { MARCO_V3_URL } = useAppData();
    const { language } = useSelector(languageSelector);

    const getStats = async () => {
        setLoading(true);
        const response = await GET(`/api/admin/stats${statLanguage ? `?language=${statLanguage}` : ""}`);
        if (response && response.stats) {
            setStats(response.stats);
            setLoading(false);
        }
    };

    useEffect(() => {
        getStats();
    }, [statLanguage]);

    if (status !== STATUS_ADMIN) {
        return <Redirect to={`/${language}/account`} />;
    }

    return (
        <Container bgColor="purple">
            <Panel />
            <Main className="admin">
                <MainWithCard
                    header={
                        <Button
                            className="button--transparent admin__back-button"
                            img="/img/icons/white-back-arrow.svg"
                            onClick={() => history.goBack()}
                            text={t("common.back")}
                        />
                    }
                >
                    <h1 className="admin__title">{t("admin.title")}</h1>
                    {!loading ? (
                        <>
                            <SelectField
                                input={{
                                    name: "statLanguage",
                                    onChange: value => setStatLanguage(value),
                                    value: statLanguage,
                                }}
                                label={t("admin.stat.language")}
                                options={languages.map(({ keyLang, label }) => ({ value: keyLang, label }))}
                                hasAllOption
                            />
                            <ul className="admin__list">
                                <li>{`${t("admin.client_number")} ${nbClients}`}</li>
                                <li>{`${t("admin.client_number_7_days")} ${nbClientsLastSevenDays}`}</li>
                                <li>{`${t("admin.forms_over")} ${nbCompletedForms}`}</li>
                                <li>{`${t("admin.forms_over_7_days")} ${nbCompletedFormsLastSevenDays}`}</li>
                            </ul>
                            <ExportForm />
                            <div className="to-v3 base-margin">
                                <a href={`${MARCO_V3_URL}/fr/admin`}>{t("admin.to_marco_v3")}</a>
                            </div>
                        </>
                    ) : (
                        <div className="admin__spinner">
                            <Spinner color="black" />
                        </div>
                    )}
                </MainWithCard>
            </Main>
        </Container>
    );
};

export default Admin;
