import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import Phase from "../../../../constants/phase.types";
import Button from "../../../components/common/Button";
import Container from "../../../components/layout/Container";
import MainWithStep from "../../../components/layout/MainWithStep";
import Panel from "../../../components/layout/Panel";
import DescriptionInterstep from "../../../components/PanelDescription/DescriptionInterstep";
import StepList from "../../../components/StepList";
import requireLastFormId from "../../../hoc/requireLastFormId";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import { phaseSelector } from "../../../store/slices/exerciseData";
import { getExerciseInfos } from "../../../utils/exercises";
import "./style.scss";

const StepTransition = () => {
    const { t } = useTranslation();
    const { step } = useParams();
    const push = useLocalizedHistoryPush();
    const exerciseNumber = parseInt(step, 10);
    const phase = useSelector(phaseSelector);
    const {
        transition: { description, subTitle, descriptionMini },
    } = getExerciseInfos(phase, exerciseNumber);

    let descriptionMainWithStep = null;
    if (phase === Phase.MINI_MARCO && descriptionMini) {
        descriptionMainWithStep = (
            <>
                <Trans i18nKey={descriptionMini} />
                <p>{t("exercise.transition.description.continue.mini")}</p>
            </>
        );
    } else {
        descriptionMainWithStep = description && (
            <>
                <Trans i18nKey={description} />
                <p>{t("exercise.transition.description.continue")}</p>
            </>
        );
    }

    return (
        <Container>
            <Panel>
                <DescriptionInterstep />
            </Panel>
            <MainWithStep
                className="step-transition"
                footer={
                    <Button
                        className="button--black button--block-mobile"
                        onClick={() => push(`/step/${exerciseNumber + 1}`)}
                        text={t("exercise.transition.continue")}
                    />
                }
                subtitle={descriptionMainWithStep}
                title={subTitle && t(subTitle)}
            >
                <StepList
                    currentStep={exerciseNumber}
                    phase={phase}
                />
            </MainWithStep>
        </Container>
    );
};

export default compose(requireLastFormId)(StepTransition);
