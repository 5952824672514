import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import Phase from "../../../../constants/texts";
import Container from "../../../components/layout/Container";
import Main from "../../../components/layout/Main";
import MainWithCard from "../../../components/layout/MainWithCard";
import Panel from "../../../components/layout/Panel";
import DescriptionForm from "../../../components/PanelDescription/DescriptionForm";
import ProfilingForm from "../../../components/ProfilingForm";
import requireLastFormId from "../../../hoc/requireLastFormId";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import { saveSocioDemoResponse } from "../../../store/actions";
import { phaseSelector } from "../../../store/slices/exerciseData";
import { goToProcessing } from "../../../utils/routes";

const Form = ({ lastFormId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const completedAt = useSelector(state => state.reconnect.lastForm && state.reconnect.lastForm.completedAt);
    const miniCompletedAt = useSelector(state => state.reconnect.lastForm && state.reconnect.lastForm.miniCompletedAt);
    const phase = useSelector(phaseSelector);

    const submitForm = async values => {
        dispatch(saveSocioDemoResponse(values, lastFormId, phase));
    };

    const goToResults = () => {
        push(goToProcessing(lastFormId));
    };

    // if roti has already been answered for mini and complete, redirect to results
    if ((phase === Phase.MINI_MARCO && miniCompletedAt) || (phase === Phase.MAXI_MARCO && completedAt)) {
        goToResults();

        return null;
    }

    if (!lastFormId) {
        return null;
    }

    return (
        <Container bgColor="purple">
            <Panel>
                <DescriptionForm />
            </Panel>
            <Main>
                <MainWithCard title={t("sociodemo.title")}>
                    <ProfilingForm onSubmit={values => submitForm(values)} onSubmitSuccess={goToResults} />
                </MainWithCard>
            </Main>
        </Container>
    );
};

Form.propTypes = {
    lastFormId: PropTypes.string,
};

export default compose(requireLastFormId)(Form);
