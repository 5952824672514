import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import webviewDetection from '../utils/webviewDetection';

const AppDataContext = createContext({});

const defaultCtx = {
    isWebview: false,
};

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children, appData }) => {
    const [state, setState] = useState({
        ...defaultCtx,
        ...appData,
    });

    useEffect(() => {
        function getEnvironment() {
            const isWebview = webviewDetection();

            setState({
                ...state,
                isWebview,
            });
        }

        getEnvironment();
    }, []);

    return <AppDataContext.Provider value={state}>{children}</AppDataContext.Provider>;
};

AppDataProvider.propTypes = {
    appData: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
};
