import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from './Footer/index';
import './style.scss';

const PageLayout = ({
    backpage = false,
    date,
    hasFooter = true,
    customFooterText,
    page,
    children,
    user,
    withoutPadding = false,
}) => (
    <div className={classnames('pdf__container', { backpage, 'without-padding': withoutPadding })}>
        <div className="pdf__content">{children}</div>
        {hasFooter && (
            <Footer
                createdAt={date}
                customText={customFooterText}
                pageNb={page}
                user={user}
            />
        )}
    </div>
);

PageLayout.propTypes = {
    backpage: PropTypes.bool,
    children: PropTypes.node.isRequired,
    customFooterText: PropTypes.string,
    date: PropTypes.string,
    hasFooter: PropTypes.bool,
    page: PropTypes.number.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
    withoutPadding: PropTypes.bool,
};

export default PageLayout;
